import PropTypes from 'prop-types'
import {
  faBold,
  faCode,
  faEllipsisVertical,
  faImage,
  faItalic,
  faLink,
  faList,
  faListOl,
  faPaperclip,
  faPlus,
  faQuoteLeft,
  faUnderline,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import isUrl from 'is-url'
import {
  Editor,
  Transforms,
  Element as SlateElement,
  Node as SlateNode,
  Range,
  createEditor,
} from 'slate'
import {
  Slate,
  Editable,
  withReact,
  useSlate,
  useSelected,
  useFocused,
  useSlateStatic,
  ReactEditor,
} from 'slate-react'
import { withHistory } from 'slate-history'
import isImage from '@utils/is-image'
import uploadFile from '@components/forms/slate-textarea/uploadFile'
import ImageElement from '@components/forms/slate-textarea/elements/image'
import VideoElement from '@components/forms/slate-textarea/elements/video'
import htmlToSlate from '@utils/htmlToSlate'
import { toast } from 'sonner'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { Menu } from '@headlessui/react'
import { Grammarly, GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'
import SoraIcon, { soraSparkles } from '@components/sora-icon'

// Exporting to allow programmatically setting value from external sources.
export const useEditor = () => {
  const [editor] = useState(() => withHistory(
    withEmbeds(withFiles(withLinks(withHTML(withReact(createEditor())))))
  ))
  return editor
}

export const isBlankValue = (value) => {
  if (!value) return true
  if (!SlateNode.isNodeList(value)) return true
  if (typeof value === 'object' && (Object.keys(value).length === 0 || Object.values(value).filter(Boolean).length === 0)) return true
  if (Array.isArray(value) && value.filter(Boolean).length === 0) return true

  const hasNonTextElements = value
    .map(SlateNode.extractProps)
    .some((nodeProps) => nodeProps.type !== 'paragraph')
  const hasOnlyEmptyTextElements = value.map(SlateNode.string).join('') === ''

  return hasOnlyEmptyTextElements && !hasNonTextElements
}

export const valueOrEmptySlate = (value) =>
  !isBlankValue(value)
    ? value
    : [{ type: 'paragraph', children: [{ text: '' }] }]

export const RenderedSlate = ({ value, placeholder }) => {
  const editor = useEditor()
  const isBlank = isBlankValue(value)
  if (isBlank && placeholder) return <>{placeholder}</>

  const emptyValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]

  editor.children = !isBlank ? value : emptyValue

  return (
    <Slate
      editor={editor}
      initialValue={valueOrEmptySlate(value)}
      onChange={() => null}
    >
      <div className="slate-textearea-description">
        <Editable
          data-cy="readonly-slate"
          readOnly
          renderElement={(props) => <Element {...props} />}
          renderLeaf={(props) => <Leaf {...props} />}
        />
      </div>
    </Slate>
  )
}
RenderedSlate.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  blockSubmission: PropTypes.func,
}

export const slateEmptyValue = [{ type: 'paragraph', children: [{ text: '' }] }]

export const TextOnlySlate = ({ value, placeholder, parseText, ...rest }) => {
  if (isBlankValue(value) && placeholder) return <>{placeholder}</>

  const contentOnly = valueOrEmptySlate(value)
    .map((n) => n?.type === 'file' ? (n?.filename || '') : SlateNode.string(n))
    .join('\n')
  return <div {...rest}>{parseText ? parseText(contentOnly) : contentOnly}</div>
}
TextOnlySlate.propTypes = {
  placeholder: PropTypes.any,
  value: PropTypes.any,
  parseText: PropTypes.func,
}

const ShortSlateTextarea = forwardRef(
  (
    {
      id,
      value,
      onChange,
      onSave,
      onFocus,
      onBlur,
      className = '',
      autoFocus = false,
      blockSubmission,
      placeholder,
      postable,
      compressed,
    },
    ref
  ) => {
    const editor = useEditor()
    const defaultValue = useMemo(() => valueOrEmptySlate(value), [value])
    const [localValue, setLocalValue] = useState(defaultValue)

    useImperativeHandle(ref, () => ({
      focus() {
        ReactEditor.focus(editor)
      },
      value: localValue,
    }))

    const handleKeyDown = event => {
      if (event.ctrlKey || event.metaKey || event.altKey) return
      if (event.key.length === 1 || event.key === 'Enter') {
        const { selection } = editor
        if (selection && Range.isExpanded(selection)) {
          event.preventDefault()
          Transforms.delete(editor, { at: selection }) // Delete the selection
          if (event.key === 'Enter') {
            Transforms.insertNodes( // Handle Enter key
              editor,
              { type: 'paragraph', children: [{ text: '' }] },
              { split: true }
            )
          } else {
            Editor.insertText(editor, event.key)
          }
          return true
        }
      }
    }

    return (
      <>
        <input type="hidden" ref={ref} value={JSON.stringify(localValue)} />
        <Grammarly
          clientId="client_MVFQqneiLuFfYAXS6QfGiS"
          config={{
            // documentDialect: "",
            autocomplete: "on",
          }}
        >
          <GrammarlyEditorPlugin>
            <Slate
              editor={editor}
              initialValue={defaultValue}
              onChange={(v) => {
                const isAstChange = editor.operations.some(
                  op => 'set_selection' !== op.type
                )
                if (isAstChange) {
                  setLocalValue(v)
                  onChange && onChange(v)
                }
              }}
            >
              <div
                className={`bg-white flex flex-col p-1 border border-gray-30 rounded-xl min-w-64 ${className} relative`}
              >
                <ul className="flex sticky -top-4 z-5 h-[33px] text-black gap-1 pb-1 border border-gray-30 bg-white rounded-lg">
                  <li className="active:bg-gray-30 a: hover:bg-gray-10 rounded-lg">
                    <BlockButton format="block-quote" icon={faQuoteLeft} />
                  </li>
                  <li className="hover:bg-gray-10 rounded-lg">
                    <MarkButton format="bold" icon={faBold} />
                  </li>
                  <li className="hover:bg-gray-10 rounded-lg">
                    <MarkButton format="italic" icon={faItalic} />
                  </li>
                  <li className="hover:bg-gray-10 rounded-lg">
                    <MarkButton format="underline" icon={faUnderline} />
                  </li>
                  <li className="hover:bg-gray-10 rounded-lg">
                    <BlockButton format="numbered-list" icon={faListOl} />
                  </li>
                  <li className="hover:bg-gray-10 rounded-lg">
                    <BlockButton format="bulleted-list" icon={faList} />
                  </li>
                  {compressed && (
                    <li>
                      <Menu as="div" className="relative inline-block text-left">
                        <Menu.Button className="hover:bg-gray-10 rounded-lg w-8 h-8">
                          <FontAwesomeIcon icon={faPlus} />
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </Menu.Button>
                        <Menu.Items className="absolute right-0 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none">
                          <Menu.Item>
                            <div className="hover:bg-gray-10 rounded-lg">
                              <LinkButton />
                            </div>
                          </Menu.Item>
                          <Menu.Item className="hover:bg-gray-10 rounded-lg">
                            <div className="hover:bg-gray-10 rounded-lg">
                              <InsertImageButton format="image" icon={faImage} />
                            </div>
                          </Menu.Item>
                          <Menu.Item className="hover:bg-gray-10 rounded-lg">
                            <div className="hover:bg-gray-10 rounded-lg">
                              <MarkButton format="code" icon={faCode} />
                            </div>
                          </Menu.Item>
                          <Menu.Item className="hover:bg-gray-10 rounded-lg">
                            <div className="hover:bg-gray-10 rounded-lg">
                              <InsertFileButton format="file" icon={faPaperclip} />
                            </div>
                          </Menu.Item>
                        </Menu.Items>
                      </Menu>
                    </li>
                  )}
                  {!compressed && (
                    <>
                      <li className="hover:bg-gray-10 rounded-lg">
                        <LinkButton />
                      </li>
                      <li className="hover:bg-gray-10 rounded-lg">
                        <InsertImageButton format="image" icon={faImage} />
                      </li>
                      <li className="hover:bg-gray-10 rounded-lg">
                        <MarkButton format="code" icon={faCode} />
                      </li>
                      <li className="hover:bg-gray-10 rounded-lg">
                        <InsertFileButton format="file" icon={faPaperclip} />
                      </li>
                    </>
                  )}
                </ul>
                <div className="p-2 flex-1 flex flex-col relative">
                  <Editable
                    className="flex-1 py-2 px-3 w-full slate-textearea-description"
                    renderElement={(props) => (
                      <Element {...props} blockSubmission={blockSubmission} />
                    )}
                    renderLeaf={(props) => <Leaf {...props} />}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    spellCheck={false}
                    autoComplete="false"
                    autoCorrect="false"
                    id={id}
                    onKeyDown={handleKeyDown}
                    data-cy="slate-editable-text-area"
                  />
                  {postable && (
                    <div className="text-right">
                      <span
                        className="capitalize hover:underline font-bold cursor-pointer text-blue-60"
                        onClick={onSave}
                      >
                        Save
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Slate>
          </GrammarlyEditorPlugin>
        </Grammarly>
      </>
    )
  }
)

ShortSlateTextarea.displayName = 'ShortSlateTextarea'
ShortSlateTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  blockSubmission: PropTypes.func,
  placeholder: PropTypes.string,
  postable: PropTypes.bool,
  compressed: PropTypes.bool,
}

export const SingleSlateTextarea = forwardRef(
  (
    {
      id,
      value,
      onChange,
      className = '',
      autoFocus = false,
      blockSubmission,
      placeholder,
      onSend,
      hideIcons = false,
      avatarComponent,
      hideSend = false,
      hideAi = true,
      onClickAi,
      customEditor,
    },
    ref
  ) => {
    const internalEditor = useEditor() // Still allocating to prevent "conditional hooks warning"
    const editor = customEditor || internalEditor
    const defaultValue = valueOrEmptySlate(value)
    const [localValue, setLocalValue] = useState(defaultValue)
    useImperativeHandle(ref, () => ({
      focus() {
        ReactEditor.focus(editor)
      }
    }))

    const handleKeyDown = event => {
      if (event.ctrlKey || event.metaKey || event.altKey) return
      if (event.key.length === 1 || event.key === 'Enter') {
        const { selection } = editor
        if (selection && Range.isExpanded(selection)) {
          event.preventDefault()
          Transforms.delete(editor, { at: selection }) // Delete the selection
          if (event.key === 'Enter') {
            Transforms.insertNodes( // Handle Enter key
              editor,
              { type: 'paragraph', children: [{ text: '' }] },
              { split: true }
            )
          } else {
            Editor.insertText(editor, event.key)
          }
          return true
        }
      }
    }

    return (
      <div className={className}>
        <input type="hidden" ref={ref} value={JSON.stringify(localValue)} />
        <Grammarly
          clientId="client_MVFQqneiLuFfYAXS6QfGiS"
          config={{
            // documentDialect: "",
            autocomplete: "on",
          }}
        >
          <GrammarlyEditorPlugin>
            <Slate
              editor={editor}
              initialValue={defaultValue}
              onChange={(v) => {
                const isAstChange = editor.operations.some(
                  op => 'set_selection' !== op.type
                )
                if (isAstChange) {
                  setLocalValue(v)
                  onChange && onChange(v)
                }
              }}
            >
              <div className="flex flex-row items-start gap-2 pt-1">
                <div className="pt-px">
                  {avatarComponent && avatarComponent}
                  {!avatarComponent && <div className="rounded-full bg-gray-30 w-7 h-7"></div>}
                </div>
                <Editable
                  className="flex-1 py-1 overflow-auto"
                  renderElement={(props) => (
                    <Element {...props} blockSubmission={blockSubmission} />
                  )}
                  renderLeaf={(props) => <Leaf {...props} />}
                  autoFocus={autoFocus}
                  placeholder={placeholder}
                  onKeyDown={handleKeyDown}
                  spellCheck={false}
                  autoComplete="false"
                  autoCorrect="false"
                  id={id}
                  data-cy="slate-editable-text-area"
                />
                {!hideIcons && (
                  <div className="flex flex-row self-end">
                    <div className="self-center rounded-full hover:bg-gray-10">
                      <InsertFileButton format="file" icon={faPaperclip} />
                    </div>
                    {!hideSend &&
                      <button
                        className={`self-center rounded-full py-1 disabled:opacity-20 ${isBlankValue(value)
                          ? 'cursor-default'
                          : 'hover:bg-gray-10'
                          }`}
                        disabled={isBlankValue(value)}
                        onClick={onSend}
                      >
                        <span className="p-2">
                          <FontAwesomeIcon icon={faPaperPlane} />{' '}
                        </span>
                      </button>
                    }

                    {/* We can make a side icon element or make an Slate AI text area insrtead of adding properties*/}
                    {!hideAi &&
                      <button
                        className={`self-center rounded-full py-1 disabled:opacity-20 hover:bg-gray-10`}
                        onClick={onClickAi}
                      >
                        <span className="">
                          <SoraIcon icon={soraSparkles} className="fill-current" />
                        </span>
                      </button>
                    }
                  </div>
                )}
              </div>
            </Slate>
          </GrammarlyEditorPlugin>
        </Grammarly>
      </div>
    )
  }
)

SingleSlateTextarea.displayName = 'SingleSlateTextarea'
SingleSlateTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.any,
  value: PropTypes.any,
  blockSubmission: PropTypes.func,
  placeholder: PropTypes.string,
  onSend: PropTypes.func,
  hideIcons: PropTypes.bool,
  avatarComponent: PropTypes.node,
  hideSend: PropTypes.bool,
  hideAi: PropTypes.bool,
  onClickAi: PropTypes.func,
  customEditor: PropTypes.any,
}

function getYoutubeId(url) {
  const youtubeRegex =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(youtubeRegex)

  return match && match[2].length === 11 ? match[2] : null
}

function getVimeoId(url) {
  const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i
  const match = url.match(vimeoRegex)
  return match ? match[1] : null
}

const withEmbeds = (editor) => {
  const { insertData, isVoid } = editor
  editor.isVoid = (element) =>
    element.type === 'video' ? true : isVoid(element)

  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    if (isUrl(text) && getYoutubeId(text)) {
      const url = `https://www.youtube.com/embed/${getYoutubeId(text)}`
      const embed = { type: 'video', url, children: [{ text: '' }] }
      Transforms.insertNodes(editor, embed)
      editor.onChange(editor.children)
    } else if (isUrl(text) && getVimeoId(text)) {
      const url = `https://player.vimeo.com/video/${getVimeoId(text)}`
      const embed = { type: 'video', url, children: [{ text: '' }] }
      Transforms.insertNodes(editor, embed)
      editor.onChange(editor.children)
    } else {
      insertData(data)
    }
  }
  return editor
}

const GenericFileElement = ({
  attributes,
  children,
  element,
  blockSubmission = () => { },
}) => {
  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()
  const [progress, setProgress] = useState()

  const actualFilename = element.filename || element.file?.name
  const ext = actualFilename?.split('.')[1]

  useEffect(() => {
    const file = element.file
    if (!element.url && file) {
      blockSubmission(true)
      const compressedFile = new File([file], file.name)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const path = ReactEditor.findPath(editor, element)
        uploadFile(compressedFile, setProgress)
          .then((uploadedFile) => {
            const newProperties = {
              url: uploadedFile.url,
              filename: uploadedFile.name,
            }
            Transforms.setNodes(editor, newProperties, { at: path })
            editor.onChange(editor.children)
            blockSubmission(false)
          })
          .catch((error) => {
            toast.error(error.message)
            const newProperties = {
              error: error.message,
              filename: actualFilename,
            }
            Transforms.setNodes(editor, newProperties, { at: path })
            editor.onChange(editor.children)
            blockSubmission(false)
          })
      })
      reader.readAsDataURL(compressedFile)
    }
    return () => { }
    // TODO: We should remove & fix this in the future
    // eslint-disable-next-line
  }, [])

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={{ caretColor: 'transparent', userSelect: "none" }}
    >
      {children}
      <div className="flex flex-col items-center justify-center">
        <a
          href={element.url}
          download={actualFilename}
          className={`relative w-32 h-48 rounded border-2 ${element.error ? 'border-danger-10' : ''
            } ${selected && focused ? 'ring-3 ring-blue-30' : ''}`}
        >
          {progress !== undefined && progress < 100 && (
            <div className="absolute rounded flex items-center justify-center h-full w-full top-0 left-0 opacity-70 bg-black">
              <h1 className="text-white">{progress}%</h1>
            </div>
          )}
          <div
            className={`no-underline uppercase h-full w-full flex items-center justify-center ${element.error ? 'bg-danger-2' : ''
              }`}
          >
            <h3>{ext}</h3>
          </div>
        </a>
        <a
          href={element.url}
          download
          className="text-xs text-center m-auto max-w-60 p-2"
        >
          {actualFilename}
        </a>
      </div>
    </div>
  )
}

GenericFileElement.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.any,
    }),
    error: PropTypes.string,
    filename: PropTypes.oneOfType([
      PropTypes.shape({
        split: PropTypes.func,
      }),
      PropTypes.string,
    ]),
    url: PropTypes.any,
  }),
  blockSubmission: PropTypes.func,
}

const Element = (props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'block-quote':
    case 'quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'code':
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      )
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'image':
      return <ImageElement {...props} />
    case 'video':
      return <VideoElement {...props} />
    case 'file':
      return <GenericFileElement {...props} />
    case 'link':
      return (
        <a
          title={element.url}
          href={element.url}
          {...attributes}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

Element.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.shape({
    type: PropTypes.any,
    url: PropTypes.any,
  }),
  blockSubmission: PropTypes.func,
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong {...attributes}>{children}</strong>
  }

  if (leaf.italic) {
    children = <em {...attributes}>{children}</em>
  }

  if (leaf.code) {
    children = <code {...attributes}>{children}</code>
  }

  if (leaf.underline) {
    children = <u {...attributes}>{children}</u>
  }

  if (leaf.strikethrough) {
    children = <del {...attributes}>{children}</del>
  }

  return <span {...attributes}>{children}</span>
}

Leaf.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  leaf: PropTypes.shape({
    bold: PropTypes.any,
    code: PropTypes.any,
    italic: PropTypes.any,
    strikethrough: PropTypes.any,
    underline: PropTypes.any,
  }),
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const isVoidActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => {
      return editor.isVoid(n) && SlateElement.isElement(n) && n.type === format
    },
  })
  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  })

  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }

  editor.onChange(editor.children)
}

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const withHTML = (editor) => {
  const { insertData, isInline, isVoid } = editor

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const html = data.getData('text/html')

    if (html) {
      const fragment = htmlToSlate(html)
      Transforms.insertFragment(editor, fragment)
      editor.onChange(editor.children)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = (editor) => {
  return isBlockActive(editor, 'link')
}

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  editor.onChange(editor.children)
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
  editor.onChange(editor.children)
}

const LinkButton = () => {
  const editor = useSlate()
  if (isLinkActive(editor))
    return (
      <Button
        isActive={false}
        onMouseDown={(event) => {
          event.preventDefault()
          if (isLinkActive(editor)) {
            unwrapLink(editor)
          }
        }}
      >
        <FontAwesomeIcon icon={faUnlink} />
      </Button>
    )
  return (
    <Button
      isActive={false}
      onMouseDown={(event) => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <FontAwesomeIcon icon={faLink} />
    </Button>
  )
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      type="button"
      isActive={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}
BlockButton.propTypes = {
  format: PropTypes.any,
  icon: PropTypes.any,
}

const Button = ({ isActive, className = '', children, ...props }) => {
  return (
    <button
      type="button"
      className={`w-8 h-8 flex flex-row rounded-lg justify-center items-center ${isActive ? 'bg-blue-60 text-white' : ''
        } ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isActive: PropTypes.any,
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

MarkButton.propTypes = {
  format: PropTypes.any,
  icon: PropTypes.any,
}

const InsertFileButton = ({ icon }) => {
  const editor = useSlate()
  const fileInputRef = useRef(null)

  const handleFileChange = (e) => {
    for (const file of e.target.files) {
      insertGenericFile(editor, {
        file,
      })
    }
  }

  return (
    <Button
      isActive={isVoidActive(editor, 'file')}
      onMouseDown={(event) => {
        event.preventDefault()
        fileInputRef.current.click()
      }}
    >
      <input
        type="file"
        className="hidden"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

InsertFileButton.propTypes = {
  icon: PropTypes.any,
}

const InsertImageButton = ({ icon }) => {
  const editor = useSlate()
  const fileInputRef = useRef(null)

  const handleFileChange = (e) => {
    for (const file of e.target.files) {
      const [mime] = file.type.split('/')
      if (mime === 'image') {
        insertImage(editor, {
          file,
        })
      } else {
        return window.alert(
          'The file is not a image! If you want to upload a file, click on the paperclip.'
        )
      }
    }
  }

  return (
    <Button
      isActive={isVoidActive(editor, 'image')}
      onMouseDown={(event) => {
        event.preventDefault()
        fileInputRef.current.click()
      }}
    >
      <input
        type="file"
        className="hidden"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

InsertImageButton.propTypes = {
  icon: PropTypes.any,
}

const withFiles = (editor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === 'image' || element.type === 'file'
      ? true
      : isVoid(element)
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const [mime] = file.type.split('/')
        if (mime === 'image') {
          insertImage(editor, { file })
        } else {
          insertGenericFile(editor, { file })
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, {
        url: text,
      })
    } else if (isFileUrl(text)) {
      insertGenericFile(editor)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertImage = (editor, { url, file }) => {
  const text = { text: '' }
  const imageNode = { type: 'image', url, file, children: [text] }
  Transforms.insertNodes(editor, imageNode)
  editor.onChange(editor.children)
}

const insertGenericFile = (editor, { file } = {}) => {
  const text = { text: '' }
  const fileNode = { type: 'file', file, children: [text], date: Date.now() }
  Transforms.insertNodes(editor, fileNode)
  editor.onChange(editor.children)
}

const getFileExtensionFromUrl = (url) => {
  try {
    new URL(
      url,
      window.location.protocol + '//' + window.location.hostname
    ).pathname
      .split('.')
      .pop()
  } catch (err) {
    return false
  }
}

const verifyURLFormat = (url) =>
  new RegExp(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
  ).test(url)

const isFileUrl = (url) => {
  if (!url) return false
  const ext = getFileExtensionFromUrl(url)
  if (!ext || ext === '/') return false
  const isURL = verifyURLFormat(url)

  if (isURL) {
    return true
  }
  return false
}

const isImageUrl = (url) => {
  if (!isFileUrl(url)) return false
  const ext = getFileExtensionFromUrl(url)
  if (!ext || ext === '/') return false

  const isURL = verifyURLFormat(url)

  if (isURL) {
    return isImage(ext)
  } else {
    return false
  }
}

export default ShortSlateTextarea
