import React, { PropsWithChildren } from 'react'

type LineClampProp = 1 | 2 | 3 | 4 | 5

const LineClampClasses = {
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
  5: 'line-clamp-5',
}

interface CardHeaderProps {
  /**
   * The title content of the component
   */
  title: string
  /**
   * The line-clamp to be add to title
   */
  titleLineClamp: LineClampProp
  /**
   * The subtitle content of the component.
   */
  subtitle?: string
  /**
   * The line-clamp to be add to subtitle
   */
  subtitleLineClamp: LineClampProp
  /**
   * The subtitle content of the component.
   */
  description?: string
  /**
   * The line-clamp to be add to subtitle
   */
  descriptionLineClamp: LineClampProp
}

/**
 * CardHeader component
 */
const CardHeader = ({
  title,
  titleLineClamp,
  subtitle,
  subtitleLineClamp,
  description,
  descriptionLineClamp
}: PropsWithChildren<CardHeaderProps>) => (
  <header className="w-full">
    <h1 title={title} className={`
      ${subtitle ? `text-2xl leading-8` : `text-3xl leading-9`}
      ${titleLineClamp ? LineClampClasses[titleLineClamp] : ``}
      font-medium whitespace-pre-line
    `}>
      {title}
    </h1>
    {subtitle && <h2 title={subtitle} className={`
      ${subtitleLineClamp ? LineClampClasses[subtitleLineClamp] : ``}
      text-base whitespace-pre-line leading-6
    `}>{subtitle}</h2>}
    {description && (
      <p title={description} className={`mt-2 font-semibold text-xs ${descriptionLineClamp ? LineClampClasses[descriptionLineClamp] : ``}`}>{description}</p>
    )}
  </header>
)

export default CardHeader
export type { CardHeaderProps }