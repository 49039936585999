import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../../lib/cn'
import { Icons } from './icons'

const iconVariants = cva(
  '!font-icon font-normal not-italic antialiased leading-none normal-case ds-icon shrink-0',
  {
    variants: {
      /** Our components typically use 24px ('md') icons on artboards. Icons on 16px ('xs'), 20px ('sm'), 24px ('md') and 32px ('lg') artboards can also be used. Be sure icon size is consistent throughout the product. */
      size: {
        '3xs': 'size-2 text-[0.5rem] leading-none',
        '2xs': 'size-3 text-xs leading-3',
        xs: 'size-4 text-base leading-4',
        sm: 'size-5 text-[1.25rem] leading-5',
        md: 'size-6 text-[1.5rem] leading-6',
        lg: 'size-8 text-[2rem] leading-8',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)

export interface IconProps extends React.HTMLAttributes<HTMLSpanElement>, VariantProps<typeof iconVariants> {
  /**
   * Icon name
   */
  name: keyof typeof Icons
}

/**
 * Icons are visual symbols used to represent ideas, objects or actions. They communicate messages at a glance, allow interactivity and draw attention to important information.
 * We use a font family using [icomoon.io/app](https://icomoon.io/app/#/select). The JSON used for generation is from the [Sora World repository](https://github.com/soraschools/sora-world/tree/main/sora_icon_font).
 */
export function Icon({ name, size, className, children, ...props }: IconProps): JSX.Element {
  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: Icons[name] }} className={cn(iconVariants({ size, className }))} role="img" aria-label={name} {...props}></span>
      {children}
    </>
  )
}

Icon.displayName = 'Icon'

