import React from 'react'

export function Select({ placeholder, options, canSelectAll, defaultSelected = "", ...rest }) {
  return (
    <select {...rest} defaultValue={defaultSelected} className={`
      block w-full px-2 rounded-lg text-base bg-white text-gray-90 border border-gray-40
      hover:bg-gray-10 hover:border-gray-50 hover:text-gray-100
      focus-within:ring-1 focus-within:ring-accent focus-within:!bg-white focus-within:text-gray-100 focus-within:border-gray-50)
    `}>
      {canSelectAll && <option value="">{placeholder || 'Select an option'}</option>}
      {
        options.map(option => (
          <option key={option.value} value={option.value} disabled={option.disabled === true}>{option.label}</option>
        ))
      }
    </select>
  )
}
