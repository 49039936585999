import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Transforms } from 'slate'
import { useSelected, useFocused, useSlateStatic, ReactEditor } from 'slate-react'
import Compressor from 'compressorjs'
import uploadFile from '@components/forms/slate-textarea/uploadFile'
import { toast } from 'sonner'

const ImageElement = ({ attributes, children, element, blockSubmission = () => { } }) => {
  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()


  const [previewUrl, setPreviewUrl] = useState()
  const [progress, setProgress] = useState()
  const url = element.url || previewUrl

  useEffect(() => {
    const file = element.file
    if (!element.url && file) {
      blockSubmission(true)
      new Compressor(file, {
        quality: 0.8,
        strict: true,
        checkOrientation: true,
        maxWidth: '2000px',
        maxHeight: '2000px',
        success: compressedBlob => {
          const compressedFile = new File([compressedBlob], compressedBlob.name)
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            setPreviewUrl(reader.result)
            uploadFile(compressedFile, setProgress).then(uploadedFile => {
              const path = ReactEditor.findPath(editor, element)
              const newProperties = {
                url: uploadedFile.url,
                filename: uploadedFile.name
              }
              Transforms.setNodes(editor, newProperties, { at: path })
              editor.onChange(editor.children)
              blockSubmission(false)
            }).catch(e => {
              toast.error(e.message)
              blockSubmission(false)
            })
          })
          reader.readAsDataURL(compressedFile)
        },
        error: err => {
          console.error(err.message)
          blockSubmission(false)
        }
      })
    }
    return () => {
    }
    // TODO: We should remove & fix this in the future
    // eslint-disable-next-line
  }, [])

  return (
    <div {...attributes}>

      <div className="relative">
        {progress !== undefined && progress < 100 &&
          <div className="absolute rounded flex items-center justify-center h-full w-full top-0 left-0 opacity-70 bg-black">
            <h1 className="text-white">{progress}%</h1>
          </div>
        }
        <div>
          <img
            src={url}
            alt="Uploaded image"
            className={`
              block w-full rounded object-contain max-h-[30rem] max-w-[30rem]
              ${selected && focused && 'ring-3 ring-blue-30'}
            `}
          />
        </div>
      </div>
      {children}
    </div>
  )
}

ImageElement.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.any
    }),
    url: PropTypes.any
  }),
  blockSubmission: PropTypes.func,
}

export default ImageElement
