import React, { PropsWithChildren } from 'react'

interface CardMediaProps {
  /**
   * Image source url 
   */
  src: string
  /**
   * Alternative text
   */
  alt?: string
}

/**
 * CardMedia component
 */
const CardMedia = ({
  src,
  alt
}: PropsWithChildren<CardMediaProps>) => {
  return (
    <div className="flex-1 min-h-0">
      <img src={src} alt={alt} className={`h-full w-full object-fit object-center`} />
    </div>
  )
}

export default CardMedia
export type { CardMediaProps }