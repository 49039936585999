import React, { ElementType } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp, SizeProp as FaSizeProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import { Icon } from '../Icon'

const DEFAULT_ELEMENT = 'button'

export type VariantProp = 'link' | 'filled' | 'outlined' | 'nude' | 'inherit'
export type ColorProp = 'neutral' | 'cta' | 'gray' | 'danger' | 'success'
export type SizesProp = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'auto'
export type DisplayProp = 'inline' | 'block'

type VariantClassesProp = { [variant in Exclude<VariantProp, 'inherit'>]: string }
type VariantColorsClassesProp = {
  [color in ColorProp]: {
    [variant in Exclude<VariantProp, 'inherit'>]: string
  }
}
type SizesClassesProp = {
  default: { [size in SizesProp]: string },
  icon: { [size in SizesProp]: string }
}
type IconSizesProp = {
  [size in SizesProp]: FaSizeProp
}

type SoraIconSizesProps = {
  [size in SizesProp]: React.ComponentProps<typeof Icon>['size']
}

export const VariantClasses: VariantClassesProp = {
  filled: 'rounded-full',
  outlined: 'rounded-full border-2',
  nude: 'rounded-full',
  link: 'border-none rounded-none hover:underline ring-0 bg-transparent w-auto outline-none active:outline-none active:ring-0 active:border-none',
}

export const DisabledClasses: VariantClassesProp = {
  filled: 'bg-gray-50 text-white dark:bg-gray-80 dark:text-gray-60',
  outlined: 'border-gray-50 bg-transparent text-gray-50 dark:border-gray-80 dark:bg-gray-90 dark:text-gray-70',
  nude: 'text-gray-50 dark:text-gray-70',
  link: 'text-gray-50 dark:text-gray-70',
}

export const ColorsClasses: VariantColorsClassesProp = {
  neutral: {
    filled: 'bg-black text-white hover:bg-gray-90 active:bg-gray-90 dark:bg-gray-5 dark:text-gray-100 dark:hover:bg-gray-30 dark:active:bg-gray-10',
    outlined: 'border-black bg-transparent text-black hover:bg-alpha/10 active:bg-alpha/10 dark:border-gray-5 dark:bg-transparent dark:text-gray-5 dark:active:bg-alpha/10',
    nude: 'text-black hover:bg-gray-10 active:bg-gray-15 dark:text-gray-5 dark:active:bg-alpha/10',
    link: 'text-black dark:text-gray-5',
  },
  cta: {
    filled: 'bg-accent text-white hover:bg-blue-40 active:bg-blue-60 dark:text-gray-100 dark:hover:bg-blue-10 dark:active:bg-blue-30',
    outlined: 'border-accent bg-transparent text-accent hover:bg-alpha/5 active:bg-alpha/10 dark:bg-transparent dark:active:bg-alpha/10',
    nude: 'text-accent hover:bg-gray-10 active:bg-gray-15 dark:active:bg-alpha/10',
    link: 'text-accent',
  },
  gray: {
    filled: 'bg-gray-40 text-gray-80 hover:bg-gray-30 active:bg-gray-20 dark:bg-gray-70 dark:text-gray-20 dark:hover:bg-gray-80 dark:active:bg-gray-90',
    outlined: 'border-gray-40 bg-transparent text-gray-80 hover:bg-alpha/5 active:bg-alpha/10 dark:border-gray-50 dark:bg-transparent dark:text-gray-50 dark:active:bg-alpha/10',
    nude: 'bg-transparent text-gray-80 hover:bg-alpha/5 active:bg-alpha/10 dark:bg-transparent dark:text-gray-40 dark:active:bg-alpha/10',
    link: 'text-gray-80 dark:text-gray-40',
  },
  danger: {
    filled: 'bg-danger-50 text-white hover:bg-danger-40 active:bg-danger-60 dark:bg-danger-20 dark:text-gray-100 dark:hover:bg-danger-30 dark:active:bg-danger-40',
    outlined: 'border-danger-50 bg-transparent text-danger-50 hover:bg-alpha/5 active:bg-alpha/10 hover:bg-alpha/5 active:bg-alpha/10 dark:border-danger-20 dark:text-danger-20 dark:active:bg-alpha/10',
    nude: 'text-danger-50 hover:bg-alpha/5 active:bg-alpha/10 dark:text-danger-20 dark:active:bg-alpha/10',
    link: 'text-danger-50 dark:text-danger-20',
  },
  success: {
    filled: 'bg-success-60 text-white hover:bg-success-40 active:bg-success-60 dark:bg-success-20 dark:text-black dark:hover:bg-success-40 dark:active:bg-success-60',
    outlined: 'border-success-60 bg-transparent text-success-60 hover:bg-alpha/5 active:bg-alpha/10 dark:border-success-20 dark:bg-gray-90 dark:text-success-30 dark:active:bg-alpha/10',
    nude: 'text-success-60 hover:bg-alpha/5 active:bg-alpha/10 dark:text-success-20 dark:active:bg-alpha/10',
    link: 'text-success-60 dark:text-success-20',
  },
}

export const SizesClasses: SizesClassesProp = {
  default: {
    xxs: 'h-6 py-1 px-2 text-sm font-semibold',
    xs: 'h-8 py-1 px-4 text-sm font-semibold',
    sm: 'h-9 py-2 px-5 text-md font-semibold',
    md: 'h-11 py-4 px-6 text-base font-semibold',
    lg: 'h-14 py-5 px-8 text-lg font-semibold',
    auto: 'py-2 px-2 text-base font-semibold'
  },
  icon: {
    xxs: 'h-6 w-6 min-w-6',
    xs: 'h-8 w-8 min-w-8',
    sm: 'h-9 px-4',
    md: 'h-11 px-5',
    lg: 'h-14 px-8',
    auto: 'px-4',
  }
}

export const IconSizes: IconSizesProp = {
  xxs: 'xs',
  xs: 'xs',
  sm: 'xs',
  md: null,
  lg: 'lg',
  auto: null,
}

const SoraIconSizes: SoraIconSizesProps = {
  xxs: '8',
  xs: '16',
  sm: '16',
  md: '24',
  lg: '32',
  auto: '32',
}

interface ButtonOwnProps<T extends ElementType> {
  /**
   * An override of the default HTML tag. Can also be another React component.
   * All tag parameters are available
   * Examples: button, a, Link, SoraLink, etc
   */
  as?: T
  /**
   * Variant of the button style
   */
  variant?: VariantProp
  /**
   * The color of the component.
   */
  color?: ColorProp
  /**
   * The size of the button
   */
  size?: SizesProp
  /**
   * Icon placed before the children.
   */
  startIcon?: IconProp | React.ComponentProps<typeof Icon>['name']
  /**
   * Icon placed after the children.
   */
  endIcon?: IconProp | React.ComponentProps<typeof Icon>['name']
  /**
   * If the button will be inline or block
   */
  display?: DisplayProp
  /**
   * If true, the button will be disabled and replace startIcon with a loading icon.
   */
  loading?: boolean
  /**
   * 	If true, the component is disabled.
   */
  disabled?: boolean
  /**
   * Pass a string to render as tooltip
   */
  tooltip?: string
}

export type ButtonProps<
  T extends ElementType = typeof DEFAULT_ELEMENT
> = PolymorphicPropsWithoutRef<ButtonOwnProps<T>, T>

/**
 * Adaptable button component for triggering actions or controls.
 */
const Button = <E extends ElementType = typeof DEFAULT_ELEMENT>({
  as,
  children,
  variant,
  color,
  size,
  display,
  startIcon,
  endIcon,
  disabled,
  loading,
  tooltip,
  ...rest
}: ButtonProps<E>
): JSX.Element => {
  const sizeType = !children && (startIcon || endIcon) ? 'icon' : 'default'
  const randomId = String(Math.random())
  const Element: ElementType = as
  return (
    <>
      <Element
        role="button"
        data-testid="button"
        {...rest}
        disabled={disabled || loading}
        className={`appearance-none flex-nowrap font-bold whitespace-nowrap box-border transition duration-300 items-center justify-center gap-3 cursor-pointer outline-none focus:ring-2 focus:ring-offset-1
          ${display === 'inline' ? 'inline-flex' : 'flex w-full'}
          ${VariantClasses[variant]} ${SizesClasses[sizeType][size]}
          ${disabled ? DisabledClasses[variant] : ColorsClasses[color][variant]}
          ${disabled || loading ? `pointer-events-none` : ``}
        `}
        data-tip={tooltip}
        data-for={randomId}
      >
        {(startIcon || loading) && <ButtonIcon dataTestId="startIcon" icon={loading ? faSpinner : startIcon} spin={loading} size={size} />}
        {children}
        {endIcon && <ButtonIcon icon={endIcon} size={size} dataTestId="endIcon" />}
      </Element>
      { /* @ts-ignore: Unreachable code error */}
      <ReactTooltip delayShow={500} id={randomId} />
    </>
  )
}

Button.defaultProps = {
  as: DEFAULT_ELEMENT,
  type: 'button',
  variant: 'filled',
  color: 'neutral',
  size: 'md',
  display: 'inline',
  disabled: false,
  loading: false,
}

Button.displayName = 'Button'

export { Button }

type ButtonIconProps = {
  spin?: boolean,
  dataTestId: string,
  size: Pick<ButtonProps, 'size'>['size'],
  icon: Pick<ButtonProps, 'startIcon'>['startIcon'] | Pick<ButtonProps, 'endIcon'>['endIcon'],
}

const getIconType = (icon: ButtonIconProps['icon']): 'sora' | 'fontawesome' => {
  return typeof icon === 'string' ? 'sora' : 'fontawesome'
}

const ButtonIcon = ({ icon, size, spin, dataTestId }: ButtonIconProps) => {
  let iconType = getIconType(icon)
  switch (iconType) {
    case 'fontawesome':
      return <FontAwesomeIcon data-testid={dataTestId} icon={icon as IconProp} spin={spin} className="flex" size={IconSizes[size]} />
    case 'sora':
      return <Icon data-testid={dataTestId} className="stroke-current" name={icon as React.ComponentProps<typeof Icon>['name']} size={SoraIconSizes[size]} />
    default:
      return null
  }
}
