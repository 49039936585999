
export enum Icons {
  "activity-2-filled" = "&#xebfa",
  "activity-2" = "&#xebf9",
  "activity-rec-filled" = "&#xebfb",
  "activity-rec" = "&#xe901",
  "airplay-filled" = "&#xebfc",
  "airplay" = "&#xe902",
  "alert-circle-filled" = "&#xebfe",
  "alert-circle" = "&#xebfd",
  "alert-octagon-filled" = "&#xe904",
  "alert-octagon" = "&#xe903",
  "alert-triangle-filled" = "&#xebff",
  "alert-triangle" = "&#xe905",
  "align-bottom-2-filled" = "&#xec01",
  "align-bottom-2" = "&#xe906",
  "align-bottom-filled" = "&#xec02",
  "align-bottom-rec-filled" = "&#xec03",
  "align-bottom-rec" = "&#xe907",
  "align-bottom" = "&#xec00",
  "align-center-2-filled" = "&#xec06",
  "align-center-2-rec-filled" = "&#xec07",
  "align-center-2-rec" = "&#xe908",
  "align-center-2" = "&#xec05",
  "align-center-filled" = "&#xec08",
  "align-center-rec-filled" = "&#xec09",
  "align-center-rec" = "&#xe909",
  "align-center" = "&#xec04",
  "align-horizontally-filled" = "&#xec0a",
  "align-horizontally" = "&#xe90a",
  "align-justify-2-filled" = "&#xec0d",
  "align-justify-2-rec-filled" = "&#xec0e",
  "align-justify-2-rec" = "&#xe90b",
  "align-justify-2" = "&#xec0c",
  "align-justify-filled" = "&#xec0f",
  "align-justify-rec-filled" = "&#xec10",
  "align-justify-rec" = "&#xe90c",
  "align-justify" = "&#xec0b",
  "align-left-2-filled" = "&#xec13",
  "align-left-2-rec-filled" = "&#xec14",
  "align-left-2-rec" = "&#xe90d",
  "align-left-2" = "&#xec12",
  "align-left-3-filled" = "&#xec15",
  "align-left-3" = "&#xe90e",
  "align-left-filled" = "&#xec16",
  "align-left-rec-filled" = "&#xec17",
  "align-left-rec" = "&#xe90f",
  "align-left" = "&#xec11",
  "align-middle-filled" = "&#xec19",
  "align-middle-rec-filled" = "&#xec1a",
  "align-middle-rec" = "&#xe910",
  "align-middle" = "&#xec18",
  "align-right-2-filled" = "&#xec1d",
  "align-right-2-rec-filled" = "&#xec1e",
  "align-right-2-rec" = "&#xe911",
  "align-right-2" = "&#xec1c",
  "align-right-3-filled" = "&#xec1f",
  "align-right-3" = "&#xe912",
  "align-right-filled" = "&#xec20",
  "align-right-rec-filled" = "&#xec21",
  "align-right-rec" = "&#xe913",
  "align-right" = "&#xec1b",
  "align-top-2-filled" = "&#xec23",
  "align-top-2" = "&#xe914",
  "align-top-filled" = "&#xec24",
  "align-top-rec-filled" = "&#xec25",
  "align-top-rec" = "&#xe915",
  "align-top" = "&#xec22",
  "align-vertically-filled" = "&#xec26",
  "align-vertically" = "&#xe916",
  "anchor-filled" = "&#xec28",
  "anchor-rec-filled" = "&#xec29",
  "anchor-rec" = "&#xe917",
  "anchor" = "&#xec27",
  "android-filled" = "&#xe919",
  "android" = "&#xe918",
  "apperture-filled" = "&#xec2b",
  "apperture" = "&#xec2a",
  "apple-filled" = "&#xe91b",
  "apple" = "&#xe91a",
  "appstore-filled" = "&#xec2c",
  "appstore" = "&#xe91c",
  "archive-filled" = "&#xec2e",
  "archive" = "&#xec2d",
  "arrow-bottom-circle-filled" = "&#xec31",
  "arrow-bottom-circle" = "&#xec30",
  "arrow-bottom-filled" = "&#xec32",
  "arrow-bottom-left-circle-filled" = "&#xec35",
  "arrow-bottom-left-circle" = "&#xec34",
  "arrow-bottom-left-filled" = "&#xec36",
  "arrow-bottom-left-rec-filled" = "&#xec37",
  "arrow-bottom-left-rec" = "&#xe91d",
  "arrow-bottom-left" = "&#xec33",
  "arrow-bottom-rec-filled" = "&#xec38",
  "arrow-bottom-rec" = "&#xe91e",
  "arrow-bottom-right-circle-filled" = "&#xec3b",
  "arrow-bottom-right-circle" = "&#xec3a",
  "arrow-bottom-right-filled" = "&#xec3c",
  "arrow-bottom-right-rec-filled" = "&#xec3d",
  "arrow-bottom-right-rec" = "&#xe91f",
  "arrow-bottom-right" = "&#xec39",
  "arrow-bottom" = "&#xec2f",
  "arrow-corner-ccw-lb-filled" = "&#xec3f",
  "arrow-corner-ccw-lb-rec-filled" = "&#xec40",
  "arrow-corner-ccw-lb-rec" = "&#xe920",
  "arrow-corner-ccw-lb" = "&#xec3e",
  "arrow-corner-ccw-lt-filled" = "&#xec42",
  "arrow-corner-ccw-lt-rec-filled" = "&#xec43",
  "arrow-corner-ccw-lt-rec" = "&#xe921",
  "arrow-corner-ccw-lt" = "&#xec41",
  "arrow-corner-ccw-rb-filled" = "&#xec45",
  "arrow-corner-ccw-rb-rec-filled" = "&#xec46",
  "arrow-corner-ccw-rb-rec" = "&#xe922",
  "arrow-corner-ccw-rb" = "&#xec44",
  "arrow-corner-ccw-rt-filled" = "&#xec48",
  "arrow-corner-ccw-rt-rec-filled" = "&#xec49",
  "arrow-corner-ccw-rt-rec" = "&#xe923",
  "arrow-corner-ccw-rt" = "&#xec47",
  "arrow-corner-cw-lb-filled" = "&#xec4b",
  "arrow-corner-cw-lb-rec-filled" = "&#xec4c",
  "arrow-corner-cw-lb-rec" = "&#xe924",
  "arrow-corner-cw-lb" = "&#xec4a",
  "arrow-corner-cw-lt-filled" = "&#xec4e",
  "arrow-corner-cw-lt-rec-filled" = "&#xec4f",
  "arrow-corner-cw-lt-rec" = "&#xe925",
  "arrow-corner-cw-lt" = "&#xec4d",
  "arrow-corner-cw-rb-filled" = "&#xec51",
  "arrow-corner-cw-rb-rec-filled" = "&#xec52",
  "arrow-corner-cw-rb-rec" = "&#xe926",
  "arrow-corner-cw-rb" = "&#xec50",
  "arrow-corner-cw-rt-filled" = "&#xec54",
  "arrow-corner-cw-rt-rec-filled" = "&#xec55",
  "arrow-corner-cw-rt-rec" = "&#xe927",
  "arrow-corner-cw-rt" = "&#xec53",
  "arrow-left-circle-filled" = "&#xec58",
  "arrow-left-circle" = "&#xec57",
  "arrow-left-curved-circle-filled" = "&#xec5a",
  "arrow-left-curved-circle" = "&#xec59",
  "arrow-left-filled" = "&#xec5b",
  "arrow-left-rec-filled" = "&#xec5c",
  "arrow-left-rec" = "&#xe928",
  "arrow-left" = "&#xec56",
  "arrow-right-circle-filled" = "&#xec5f",
  "arrow-right-circle" = "&#xec5e",
  "arrow-right-curved-circle-filled" = "&#xec61",
  "arrow-right-curved-circle" = "&#xec60",
  "arrow-right-filled" = "&#xec62",
  "arrow-right-rec-filled" = "&#xec63",
  "arrow-right-rec" = "&#xe929",
  "arrow-right" = "&#xec5d",
  "arrow-swap-filled" = "&#xec70",
  "arrow-swap-rec-filled" = "&#xec71",
  "arrow-swap-rec" = "&#xe92f",
  "arrow-swap" = "&#xec6f",
  "arrow-top-circle-filled" = "&#xec74",
  "arrow-top-circle" = "&#xec73",
  "arrow-top-filled" = "&#xec75",
  "arrow-top-left-circle-filled" = "&#xec78",
  "arrow-top-left-circle" = "&#xec77",
  "arrow-top-left-filled" = "&#xec79",
  "arrow-top-left-rec-filled" = "&#xec7a",
  "arrow-top-left-rec" = "&#xe930",
  "arrow-top-left" = "&#xec76",
  "arrow-top-rec-filled" = "&#xec7b",
  "arrow-top-rec" = "&#xe931",
  "arrow-top-right-circle-filled" = "&#xec7e",
  "arrow-top-right-circle" = "&#xec7d",
  "arrow-top-right-filled" = "&#xec7f",
  "arrow-top-right-rec-filled" = "&#xec80",
  "arrow-top-right-rec" = "&#xe932",
  "arrow-top-right" = "&#xec7c",
  "arrow-top" = "&#xec72",
  "arrows-expand-2-filled" = "&#xec66",
  "arrows-expand-2-rec-filled" = "&#xec67",
  "arrows-expand-2-rec" = "&#xe92a",
  "arrows-expand-2" = "&#xec65",
  "arrows-expand-filled" = "&#xec68",
  "arrows-expand-rec-filled" = "&#xec69",
  "arrows-expand-rec" = "&#xe92b",
  "arrows-expand" = "&#xec64",
  "arrows-move-2-filled" = "&#xec6c",
  "arrows-move-2-rec-filled" = "&#xec6d",
  "arrows-move-2-rec" = "&#xe92c",
  "arrows-move-2" = "&#xec6b",
  "arrows-move-filled" = "&#xec6e",
  "arrows-move-rec-filled" = "&#xe92e",
  "arrows-move-rec" = "&#xe92d",
  "arrows-move" = "&#xec6a",
  "at-filled" = "&#xec82",
  "at-rec-filled" = "&#xec84",
  "at-rec" = "&#xe934",
  "at" = "&#xec81",
  "atom-filled" = "&#xec83",
  "atom" = "&#xe933",
  "award-filled" = "&#xec86",
  "award" = "&#xec85",
  "baby-carriage-filled" = "&#xe936",
  "baby-carriage" = "&#xe935",
  "barchart-filled" = "&#xec87",
  "barchart" = "&#xe937",
  "battery-1-filled" = "&#xec88",
  "battery-1" = "&#xe939",
  "battery-2-filled" = "&#xec89",
  "battery-2" = "&#xe93a",
  "battery-3-filled" = "&#xec8a",
  "battery-3" = "&#xe93b",
  "battery-charging-filled" = "&#xe93d",
  "battery-charging" = "&#xe93c",
  "battery-filled" = "&#xec8b",
  "battery-slash-filled" = "&#xec8d",
  "battery-slash" = "&#xec8c",
  "battery" = "&#xe938",
  "bed-2-filled" = "&#xe93f",
  "bed-2" = "&#xe93e",
  "bed-3-filled" = "&#xe941",
  "bed-3" = "&#xe940",
  "behance-filled" = "&#xe943",
  "behance" = "&#xe942",
  "bell-check-filled" = "&#xec90",
  "bell-check" = "&#xec8f",
  "bell-filled" = "&#xec91",
  "bell-minus-filled" = "&#xec93",
  "bell-minus" = "&#xec92",
  "bell-plus-filled" = "&#xec95",
  "bell-plus" = "&#xec94",
  "bell-slash-filled" = "&#xec97",
  "bell-slash" = "&#xec96",
  "bell" = "&#xec8e",
  "bluetooth-filled" = "&#xe945",
  "bluetooth-rec-filled" = "&#xe947",
  "bluetooth-rec" = "&#xe946",
  "bluetooth" = "&#xe944",
  "book-filled" = "&#xec98",
  "book-library-filled" = "&#xe94a",
  "book-library" = "&#xe949",
  "book-open-filled" = "&#xe952",
  "book-open" = "&#xe951",
  "book-plus-filled" = "&#xe954",
  "book-plus" = "&#xe953",
  "book-queue-filled" = "&#xe956",
  "book-queue" = "&#xe955",
  "book" = "&#xe948",
  "bookmark-check-filled" = "&#xec99",
  "bookmark-check" = "&#xe94c",
  "bookmark-filled" = "&#xec9a",
  "bookmark-minus-filled" = "&#xec9b",
  "bookmark-minus" = "&#xe94d",
  "bookmark-plus-filled" = "&#xec9c",
  "bookmark-plus" = "&#xe94e",
  "bookmark-slash-filled" = "&#xec9e",
  "bookmark-slash" = "&#xe950",
  "bookmark" = "&#xe94b",
  "bookmarks-filled" = "&#xec9d",
  "bookmarks" = "&#xe94f",
  "border-bottom-filled" = "&#xec9f",
  "border-bottom" = "&#xe957",
  "border-clear-filled" = "&#xeca0",
  "border-clear" = "&#xe958",
  "border-common-filled" = "&#xe95a",
  "border-common" = "&#xe959",
  "border-horizontal-filled" = "&#xeca1",
  "border-horizontal" = "&#xe95b",
  "border-inner-filled" = "&#xeca3",
  "border-inner" = "&#xeca2",
  "border-left-filled" = "&#xeca4",
  "border-left" = "&#xe95c",
  "border-outer-filled" = "&#xeca5",
  "border-outer" = "&#xe95d",
  "border-right-filled" = "&#xeca6",
  "border-right" = "&#xe95e",
  "border-top-filled" = "&#xeca7",
  "border-top" = "&#xe95f",
  "border-vertical-filled" = "&#xeca8",
  "border-vertical" = "&#xe960",
  "box-2-filled" = "&#xe963",
  "box-2" = "&#xe962",
  "box-filled" = "&#xe964",
  "box" = "&#xe961",
  "brackets-filled" = "&#xe966",
  "brackets-rec-filled" = "&#xe968",
  "brackets-rec" = "&#xe967",
  "brackets" = "&#xe965",
  "briefcase-2-filled" = "&#xe96b",
  "briefcase-2" = "&#xe96a",
  "briefcase-filled" = "&#xeca9",
  "briefcase" = "&#xe969",
  "broadcast-filled" = "&#xe96d",
  "broadcast-rec-filled" = "&#xe96f",
  "broadcast-rec" = "&#xe96e",
  "broadcast" = "&#xe96c",
  "brush-filled" = "&#xe971",
  "brush" = "&#xe970",
  "bug-filled" = "&#xe973",
  "bug" = "&#xe972",
  "bulb-filled" = "&#xecab",
  "bulb-slash-filled" = "&#xecac",
  "bulb-slash" = "&#xe974",
  "bulb" = "&#xecaa",
  "calendar-arrow-right-filled" = "&#xecad",
  "calendar-arrow-right" = "&#xe976",
  "calendar-cross-filled" = "&#xecae",
  "calendar-cross" = "&#xe977",
  "calendar-dates-filled" = "&#xe979",
  "calendar-dates" = "&#xe978",
  "calendar-event-filled" = "&#xecaf",
  "calendar-event" = "&#xe97a",
  "calendar-filled" = "&#xecb0",
  "calendar-minus-filled" = "&#xecb1",
  "calendar-minus" = "&#xe97b",
  "calendar-note-filled" = "&#xecb2",
  "calendar-note" = "&#xe97c",
  "calendar-number-filled" = "&#xecb3",
  "calendar-number" = "&#xe97d",
  "calendar-person-filled" = "&#xecb4",
  "calendar-person" = "&#xe97e",
  "calendar-plus-filled" = "&#xecb5",
  "calendar-plus" = "&#xe97f",
  "calendar" = "&#xe975",
  "call-add-filled" = "&#xe981",
  "call-add" = "&#xe980",
  "call-calling-filled" = "&#xe983",
  "call-calling" = "&#xe982",
  "call-decline-filled" = "&#xecb6",
  "call-decline" = "&#xe984",
  "call-end-filled" = "&#xe986",
  "call-end" = "&#xe985",
  "call-incoming-filled" = "&#xe988",
  "call-incoming" = "&#xe987",
  "call-missed-filled" = "&#xe98a",
  "call-missed" = "&#xe989",
  "call-muted-filled" = "&#xe98c",
  "call-muted" = "&#xe98b",
  "call-on-hold-filled" = "&#xe98e",
  "call-on-hold" = "&#xe98d",
  "call-outgoing-filled" = "&#xe990",
  "call-outgoing" = "&#xe98f",
  "call-received-filled" = "&#xe992",
  "call-received" = "&#xe991",
  "call-redirected-filled" = "&#xe994",
  "call-redirected" = "&#xe993",
  "call-remove-filled" = "&#xe996",
  "call-remove" = "&#xe995",
  "call-slash-filled" = "&#xe998",
  "call-slash" = "&#xe997",
  "camera-filled" = "&#xecb7",
  "camera-slash-filled" = "&#xe99b",
  "camera-slash" = "&#xe99a",
  "camera" = "&#xe999",
  "canva-filled" = "&#xefa5",
  "canva" = "&#xefa2",
  "captions-2-filled" = "&#xe99e",
  "captions-2" = "&#xe99d",
  "captions-filled" = "&#xecb8",
  "captions" = "&#xe99c",
  "cash-filled" = "&#xe9a0",
  "cash" = "&#xe99f",
  "cast-filled" = "&#xecb9",
  "cast" = "&#xe9a1",
  "cd-filled" = "&#xecbb",
  "cd" = "&#xecba",
  "chart-bar-filled" = "&#xecbc",
  "chart-bar" = "&#xe9a2",
  "chart-column-filled" = "&#xecbd",
  "chart-column" = "&#xe9a3",
  "chart-line-filled" = "&#xecbe",
  "chart-line" = "&#xe9a4",
  "chart-pie-filled" = "&#xecbf",
  "chart-pie" = "&#xe9a5",
  "check-circle-2-filled" = "&#xecc6",
  "check-circle-2" = "&#xecc5",
  "check-circle-filled" = "&#xecc7",
  "check-circle" = "&#xecc4",
  "check-filled" = "&#xecc8",
  "check-rec-filled" = "&#xecc9",
  "check-rec" = "&#xe9a7",
  "check" = "&#xecc0",
  "checkbox-circle-filled" = "&#xecc2",
  "checkbox-circle" = "&#xecc1",
  "checkbox-rec-filled" = "&#xecc3",
  "checkbox-rec" = "&#xe9a6",
  "chevron-down-filled" = "&#xeccb",
  "chevron-down-rec-filled" = "&#xeccc",
  "chevron-down-rec" = "&#xe9a8",
  "chevron-down" = "&#xecca",
  "chevron-left-filled" = "&#xecce",
  "chevron-left-rec-filled" = "&#xeccf",
  "chevron-left-rec" = "&#xe9a9",
  "chevron-left" = "&#xeccd",
  "chevron-right-filled" = "&#xecd1",
  "chevron-right-rec-filled" = "&#xecd2",
  "chevron-right-rec" = "&#xe9aa",
  "chevron-right" = "&#xecd0",
  "chevron-up-filled" = "&#xece0",
  "chevron-up-rec-filled" = "&#xece1",
  "chevron-up-rec" = "&#xe9af",
  "chevron-up" = "&#xecdf",
  "chevrons-down-filled" = "&#xecd4",
  "chevrons-down-rec-filled" = "&#xecd5",
  "chevrons-down-rec" = "&#xe9ab",
  "chevrons-down" = "&#xecd3",
  "chevrons-left-filled" = "&#xecd7",
  "chevrons-left-rec-filled" = "&#xecd8",
  "chevrons-left-rec" = "&#xe9ac",
  "chevrons-left" = "&#xecd6",
  "chevrons-right-filled" = "&#xecda",
  "chevrons-right-rec-filled" = "&#xecdb",
  "chevrons-right-rec" = "&#xe9ad",
  "chevrons-right" = "&#xecd9",
  "chevrons-up-filled" = "&#xecdd",
  "chevrons-up-rec-filled" = "&#xecde",
  "chevrons-up-rec" = "&#xe9ae",
  "chevrons-up" = "&#xecdc",
  "clipboard-check-filled" = "&#xece2",
  "clipboard-check" = "&#xe9b1",
  "clipboard-cross-filled" = "&#xece3",
  "clipboard-cross" = "&#xe9b2",
  "clipboard-filled" = "&#xece4",
  "clipboard-minus-filled" = "&#xece5",
  "clipboard-minus" = "&#xe9b3",
  "clipboard-plus-filled" = "&#xece6",
  "clipboard-plus" = "&#xe9b4",
  "clipboard-slash-filled" = "&#xe9b6",
  "clipboard-slash" = "&#xe9b5",
  "clipboard-text-filled" = "&#xece7",
  "clipboard-text" = "&#xe9b7",
  "clipboard" = "&#xe9b0",
  "clock-check-filled" = "&#xecea",
  "clock-check" = "&#xece9",
  "clock-cross-filled" = "&#xecec",
  "clock-cross" = "&#xeceb",
  "clock-filled" = "&#xeced",
  "clock-minus-filled" = "&#xecef",
  "clock-minus" = "&#xecee",
  "clock-plus-filled" = "&#xecf1",
  "clock-plus" = "&#xecf0",
  "clock-slash-filled" = "&#xecf3",
  "clock-slash" = "&#xecf2",
  "clock-zzz-filled" = "&#xecf5",
  "clock-zzz" = "&#xecf4",
  "clock" = "&#xece8",
  "cloud-check-filled" = "&#xecf8",
  "cloud-check" = "&#xecf7",
  "cloud-cross-filled" = "&#xecfa",
  "cloud-cross" = "&#xecf9",
  "cloud-download-filled" = "&#xecfc",
  "cloud-download" = "&#xecfb",
  "cloud-drizzle-filled" = "&#xe9b9",
  "cloud-drizzle" = "&#xe9b8",
  "cloud-filled" = "&#xecfd",
  "cloud-lightning-filled" = "&#xecff",
  "cloud-lightning" = "&#xecfe",
  "cloud-snow-filled" = "&#xed01",
  "cloud-snow" = "&#xed00",
  "cloud-upload-filled" = "&#xed03",
  "cloud-upload" = "&#xed02",
  "cloud" = "&#xecf6",
  "code-filled" = "&#xed05",
  "code-rec-filled" = "&#xed06",
  "code-rec" = "&#xe9ba",
  "code" = "&#xed04",
  "coffee-filled" = "&#xe9bc",
  "coffee" = "&#xe9bb",
  "color-filter-filled" = "&#xed08",
  "color-filter" = "&#xed07",
  "column-spacing-filled" = "&#xed0a",
  "column-spacing-rec-filled" = "&#xed0b",
  "column-spacing-rec" = "&#xe9bd",
  "column-spacing" = "&#xed09",
  "comment-2-alert-filled" = "&#xed0d",
  "comment-2-alert" = "&#xe9bf",
  "comment-2-cancel-filled" = "&#xed0e",
  "comment-2-cancel" = "&#xe9c0",
  "comment-2-check-filled" = "&#xed0f",
  "comment-2-check" = "&#xe9c1",
  "comment-2-dots-filled" = "&#xed10",
  "comment-2-dots" = "&#xe9c2",
  "comment-2-filled" = "&#xed11",
  "comment-2-info-filled" = "&#xed12",
  "comment-2-info" = "&#xe9c3",
  "comment-2-minus-filled" = "&#xed13",
  "comment-2-minus" = "&#xe9c4",
  "comment-2-plus-filled" = "&#xed14",
  "comment-2-plus" = "&#xe9c5",
  "comment-2-question-filled" = "&#xed15",
  "comment-2-question" = "&#xe9c6",
  "comment-2-slash-filled" = "&#xed16",
  "comment-2-slash" = "&#xe9c7",
  "comment-2-text-filled" = "&#xed17",
  "comment-2-text" = "&#xe9c8",
  "comment-2" = "&#xed0c",
  "comment-alert-filled" = "&#xed18",
  "comment-alert" = "&#xe9c9",
  "comment-cancel-filled" = "&#xed19",
  "comment-cancel" = "&#xe9ca",
  "comment-check-filled" = "&#xed1a",
  "comment-check" = "&#xe9cb",
  "comment-dots-filled" = "&#xed1b",
  "comment-dots" = "&#xe9cc",
  "comment-filled" = "&#xed1c",
  "comment-minus-filled" = "&#xed1d",
  "comment-minus" = "&#xe9cd",
  "comment-plus-filled" = "&#xed1e",
  "comment-plus" = "&#xe9ce",
  "comment-slash-filled" = "&#xe9d2",
  "comment-slash" = "&#xe9d1",
  "comment-text-filled" = "&#xed21",
  "comment-text" = "&#xe9d3",
  "comment" = "&#xe9be",
  "comments-2-filled" = "&#xed1f",
  "comments-2" = "&#xe9d0",
  "comments-filled" = "&#xed20",
  "comments" = "&#xe9cf",
  "compass-filled" = "&#xed23",
  "compass" = "&#xed22",
  "copy-filled" = "&#xed24",
  "copy" = "&#xe9d4",
  "copyright-filled" = "&#xed26",
  "copyright" = "&#xed25",
  "credit-card-filled" = "&#xed27",
  "credit-card-income-filled" = "&#xe9d7",
  "credit-card-income" = "&#xe9d6",
  "credit-card-minus-filled" = "&#xe9d9",
  "credit-card-minus" = "&#xe9d8",
  "credit-card-outcome-filled" = "&#xe9db",
  "credit-card-outcome" = "&#xe9da",
  "credit-card-plus-filled" = "&#xe9dd",
  "credit-card-plus" = "&#xe9dc",
  "credit-card-scan-filled" = "&#xe9df",
  "credit-card-scan" = "&#xe9de",
  "credit-card-slash-filled" = "&#xe9e1",
  "credit-card-slash" = "&#xe9e0",
  "credit-card" = "&#xe9d5",
  "crest-filled" = "&#xefa1",
  "crest" = "&#xefa2", 
  "crop-filled" = "&#xed29",
  "crop-rec-filled" = "&#xe9e3",
  "crop-rec" = "&#xe9e2",
  "crop-rotate-filled" = "&#xe9e5",
  "crop-rotate-rec-filled" = "&#xe9e7",
  "crop-rotate-rec" = "&#xe9e6",
  "crop-rotate" = "&#xe9e4",
  "crop" = "&#xed28",
  "cross-circle-filled" = "&#xed2c",
  "cross-circle" = "&#xed2b",
  "cross-filled" = "&#xed2d",
  "cross-octagon-filled" = "&#xe9e9",
  "cross-octagon" = "&#xe9e8",
  "cross-rec-filled" = "&#xed30",
  "cross-rec" = "&#xe9ea",
  "cross" = "&#xed2a",
  "crosshair-filled" = "&#xed2f",
  "crosshair" = "&#xed2e",
  "cut-filled" = "&#xed32",
  "cut" = "&#xed31",
  "cutlery-2-filled" = "&#xe9ed",
  "cutlery-2" = "&#xe9ec",
  "cutlery-3-filled" = "&#xed33",
  "cutlery-3" = "&#xe9ee",
  "cutlery-4-filled" = "&#xed34",
  "cutlery-4" = "&#xe9ef",
  "cutlery-filled" = "&#xed35",
  "cutlery" = "&#xe9eb",
  "dashboard-2-filled" = "&#xe9f1",
  "dashboard-2" = "&#xe9f0",
  "degrees-filled" = "&#xebf8",
  "degrees" = "&#xe900",
  "devices-filled" = "&#xe9f3",
  "devices" = "&#xe9f2",
  "dialpad-filled" = "&#xed37",
  "dialpad" = "&#xed36",
  "direction-2-filled" = "&#xed38",
  "direction-2" = "&#xe9f5",
  "direction-filled" = "&#xed39",
  "direction" = "&#xe9f4",
  "diskette-filled" = "&#xed3a",
  "diskette" = "&#xe9f6",
  "distribute-horizontally-filled" = "&#xed3b",
  "distribute-horizontally" = "&#xe9f7",
  "distribute-vertically-filled" = "&#xed3c",
  "distribute-vertically" = "&#xe9f8",
  "dollar-circle-filled" = "&#xed3f",
  "dollar-circle" = "&#xed3e",
  "dollar-filled" = "&#xed40",
  "dollar" = "&#xed3d",
  "dribbble-filled" = "&#xed41",
  "dribbble" = "&#xe9f9",
  "droplet-filled" = "&#xed43",
  "droplet-slash-filled" = "&#xed44",
  "droplet-slash" = "&#xe9fa",
  "droplet" = "&#xed42",
  "dumbell-filled" = "&#xed45",
  "dumbell" = "&#xe9fb",
  "edit-2-filled" = "&#xed46",
  "edit-2" = "&#xe9fd",
  "edit-3-filled" = "&#xed47",
  "edit-3" = "&#xe9fe",
  "edit-filled" = "&#xe9ff",
  "edit-slash-filled" = "&#xea01",
  "edit-slash" = "&#xea00",
  "edit" = "&#xe9fc",
  "elements-2-filled" = "&#xed49",
  "elements-2" = "&#xed48",
  "elements-3-filled" = "&#xed4a",
  "elements-3" = "&#xea03",
  "elements-4-filled" = "&#xea05",
  "elements-4" = "&#xea04",
  "elements-5-filled" = "&#xea07",
  "elements-5" = "&#xea06",
  "elements-filled" = "&#xea08",
  "elements-plus-filled" = "&#xea0a",
  "elements-plus" = "&#xea09",
  "elements" = "&#xea02",
  "email-filled" = "&#xed4b",
  "email" = "&#xea0b",
  "emails-filled" = "&#xea0d",
  "emails" = "&#xea0c",
  "emotion-happy-filled" = "&#xed4d",
  "emotion-happy" = "&#xed4c",
  "emotion-neutral-filled" = "&#xed4f",
  "emotion-neutral" = "&#xed4e",
  "emotion-sad-filled" = "&#xed51",
  "emotion-sad" = "&#xed50",
  "emotion-smile-filled" = "&#xed53",
  "emotion-smile" = "&#xed52",
  "emotion-wow-filled" = "&#xed55",
  "emotion-wow" = "&#xed54",
  "equalizer-filled" = "&#xed56",
  "equalizer" = "&#xea0e",
  "euro-circle-filled" = "&#xed59",
  "euro-circle" = "&#xed58",
  "euro-filled" = "&#xed5a",
  "euro" = "&#xed57",
  "export-2-filled" = "&#xed5c",
  "export-2" = "&#xea0f",
  "export-3-filled" = "&#xed5e",
  "export-3" = "&#xed5d",
  "export-4-filled" = "&#xed60",
  "export-4" = "&#xed5f",
  "export-filled" = "&#xed61",
  "export" = "&#xed5b",
  "external-link-2-filled" = "&#xed62",
  "external-link-2" = "&#xea11",
  "external-link-filled" = "&#xea12",
  "external-link-rec-filled" = "&#xed63",
  "external-link-rec" = "&#xea13",
  "external-link" = "&#xea10",
  "eye-filled" = "&#xed65",
  "eye-slash-filled" = "&#xed66",
  "eye-slash" = "&#xea14",
  "eye" = "&#xed64",
  "face-id-filled" = "&#xea17",
  "face-id-rec-filled" = "&#xea19",
  "face-id-rec" = "&#xea18",
  "face-id" = "&#xea16",
  "facebook-filled" = "&#xed67",
  "facebook" = "&#xea15",
  "fast-forward-filled" = "&#xed68",
  "fast-forward" = "&#xea1a",
  "fast-rewind-filled" = "&#xed69",
  "fast-rewind" = "&#xea1b",
  "fat-rows-filled" = "&#xed6a",
  "fat-rows" = "&#xea1c",
  "figma-filled" = "&#xed6c",
  "figma" = "&#xed6b",
  "file-agreement-filled" = "&#xea1f",
  "file-agreement" = "&#xea1e",
  "file-check-filled" = "&#xed6d",
  "file-check" = "&#xea20",
  "file-code-filled" = "&#xea22",
  "file-code" = "&#xea21",
  "file-cross-filled" = "&#xea24",
  "file-cross" = "&#xea23",
  "file-download-filled" = "&#xea26",
  "file-download" = "&#xea25",
  "file-draft-filled" = "&#xea28",
  "file-draft" = "&#xea27",
  "file-filled" = "&#xed6e",
  "file-heart-filled" = "&#xea2a",
  "file-heart" = "&#xea29",
  "file-invoice-filled" = "&#xea2c",
  "file-invoice" = "&#xea2b",
  "file-minus-filled" = "&#xed6f",
  "file-minus" = "&#xea2d",
  "file-move-filled" = "&#xea2f",
  "file-move" = "&#xea2e",
  "file-plus-filled" = "&#xea31",
  "file-plus" = "&#xea30",
  "file-scan-filled" = "&#xed70",
  "file-scan" = "&#xea32",
  "file-shredder-filled" = "&#xed71",
  "file-shredder" = "&#xea33",
  "file-slash-filled" = "&#xea35",
  "file-slash" = "&#xea34",
  "file-text-filled" = "&#xed72",
  "file-text" = "&#xea36",
  "file-upload-filled" = "&#xea38",
  "file-upload" = "&#xea37",
  "file" = "&#xea1d",
  "film-board-filled" = "&#xea3a",
  "film-board" = "&#xea39",
  "film-reel-filled" = "&#xed74",
  "film-reel" = "&#xed73",
  "film-tape-filled" = "&#xea3c",
  "film-tape" = "&#xea3b",
  "filter-2-filled" = "&#xed77",
  "filter-2" = "&#xed76",
  "filter-filled" = "&#xed78",
  "filter-rec-filled" = "&#xed79",
  "filter-rec" = "&#xea3d",
  "filter" = "&#xed75",
  "fingerprint-2-filled" = "&#xed7c",
  "fingerprint-2-rec-filled" = "&#xea3f",
  "fingerprint-2-rec" = "&#xea3e",
  "fingerprint-2" = "&#xed7b",
  "fingerprint-filled" = "&#xed7d",
  "fingerprint-rec-filled" = "&#xea41",
  "fingerprint-rec" = "&#xea40",
  "fingerprint" = "&#xed7a",
  "fire-filled" = "&#xed7e",
  "fire" = "&#xea42",
  "first-line-1-filled" = "&#xea44",
  "first-line-1-rec-filled" = "&#xea46",
  "first-line-1-rec" = "&#xea45",
  "first-line-1" = "&#xea43",
  "first-line-2-filled" = "&#xed80",
  "first-line-2-rec-filled" = "&#xed81",
  "first-line-2-rec" = "&#xea47",
  "first-line-2" = "&#xed7f",
  "first-line-3-filled" = "&#xed83",
  "first-line-3-rec-filled" = "&#xed84",
  "first-line-3-rec" = "&#xea48",
  "first-line-3" = "&#xed82",
  "flag-2-filled" = "&#xed87",
  "flag-2" = "&#xed86",
  "flag-3-filled" = "&#xed89",
  "flag-3" = "&#xed88",
  "flag-filled" = "&#xed8a",
  "flag" = "&#xed85",
  "flashcard-filled" = "&#xea4a",
  "flashcard" = "&#xea49",
  "folder-check-filled" = "&#xed8b",
  "folder-check" = "&#xea4c",
  "folder-cloud-filled" = "&#xed8c",
  "folder-cloud" = "&#xea4d",
  "folder-compressed-filled" = "&#xed8d",
  "folder-compressed" = "&#xea4e",
  "folder-cross-filled" = "&#xed8e",
  "folder-cross" = "&#xea4f",
  "folder-download-filled" = "&#xed8f",
  "folder-download" = "&#xea50",
  "folder-filled" = "&#xed90",
  "folder-heart-filled" = "&#xed91",
  "folder-heart" = "&#xea51",
  "folder-minus-filled" = "&#xed92",
  "folder-minus" = "&#xea52",
  "folder-move-filled" = "&#xed93",
  "folder-move" = "&#xea53",
  "folder-music-filled" = "&#xed94",
  "folder-music" = "&#xea54",
  "folder-open-filled" = "&#xed95",
  "folder-open" = "&#xea55",
  "folder-photo-filled" = "&#xed96",
  "folder-photo" = "&#xea56",
  "folder-plus-filled" = "&#xed97",
  "folder-plus" = "&#xea57",
  "folder-slash-filled" = "&#xea59",
  "folder-slash" = "&#xea58",
  "folder-upload-filled" = "&#xed98",
  "folder-upload" = "&#xea5a",
  "folder" = "&#xea4b",
  "forward-10-filled" = "&#xed9e",
  "forward-10-rec-filled" = "&#xed9f",
  "forward-10-rec" = "&#xea5c",
  "forward-10" = "&#xed9d",
  "forward-15-filled" = "&#xeda1",
  "forward-15-rec-filled" = "&#xeda2",
  "forward-15-rec" = "&#xea5d",
  "forward-15" = "&#xeda0",
  "forward-5-filled" = "&#xed9b",
  "forward-5-rec-filled" = "&#xed9c",
  "forward-5-rec" = "&#xea5b",
  "forward-5" = "&#xed9a",
  "forward-all-filled" = "&#xeda4",
  "forward-all-rec-filled" = "&#xeda5",
  "forward-all-rec" = "&#xea5e",
  "forward-all" = "&#xeda3",
  "forward-filled" = "&#xeda6",
  "forward-rec-filled" = "&#xeda7",
  "forward-rec" = "&#xea5f",
  "forward" = "&#xed99",
  "frame-bounds-filled" = "&#xeda9",
  "frame-bounds-rec-filled" = "&#xedaa",
  "frame-bounds-rec" = "&#xea61",
  "frame-bounds" = "&#xeda8",
  "frame-filled" = "&#xea62",
  "frame" = "&#xea60",
  "framer-filled" = "&#xedac",
  "framer" = "&#xedab",
  "functions-filled" = "&#xedae",
  "functions-rec-filled" = "&#xedaf",
  "functions-rec" = "&#xea63",
  "functions" = "&#xedad",
  "games-filled" = "&#xea65",
  "games" = "&#xea64",
  "gear-2-filled" = "&#xea68",
  "gear-2" = "&#xea67",
  "gear-filled" = "&#xea69",
  "gear" = "&#xea66",
  "gift-filled" = "&#xedb0",
  "gift" = "&#xea6a",
  "github-filled" = "&#xedb1",
  "github" = "&#xea6b",
  "glass-cocktail-filled" = "&#xedb3",
  "glass-cocktail" = "&#xedb2",
  "glass-wine-filled" = "&#xedb6",
  "glass-wine" = "&#xedb5",
  "glasses-filled" = "&#xedb4",
  "glasses-vr-filled" = "&#xea6e",
  "glasses-vr" = "&#xea6d",
  "glasses" = "&#xea6c",
  "globe-2-filled" = "&#xedb8",
  "globe-2" = "&#xedb7",
  "globe-filled" = "&#xea70",
  "globe" = "&#xea6f",
  "googleplay-filled" = "&#xea72",
  "googleplay" = "&#xea71",
  "gps-filled" = "&#xedba",
  "gps-slash-filled" = "&#xedbc",
  "gps-slash" = "&#xedbb",
  "gps" = "&#xedb9",
  "grid-02-filled" = "&#xea75",
  "grid-02" = "&#xea74",
  "grid-03-filled" = "&#xedbd",
  "grid-03" = "&#xea76",
  "grid-04-filled" = "&#xedbe",
  "grid-04" = "&#xea77",
  "grid-05-filled" = "&#xedbf",
  "grid-05" = "&#xea78",
  "grid-06-filled" = "&#xedc0",
  "grid-06" = "&#xea79",
  "grid-07-filled" = "&#xea7b",
  "grid-07" = "&#xea7a",
  "grid-08-filled" = "&#xea7d",
  "grid-08" = "&#xea7c",
  "grid-09-filled" = "&#xea7f",
  "grid-09" = "&#xea7e",
  "grid-10-filled" = "&#xea81",
  "grid-10" = "&#xea80",
  "grid-11-filled" = "&#xea83",
  "grid-11" = "&#xea82",
  "grid-12-filled" = "&#xea85",
  "grid-12" = "&#xea84",
  "grid-13-filled" = "&#xea87",
  "grid-13" = "&#xea86",
  "grid-14-filled" = "&#xedc1",
  "grid-14" = "&#xea88",
  "grid-15-filled" = "&#xedc2",
  "grid-15" = "&#xea89",
  "grid-16-filled" = "&#xea8b",
  "grid-16" = "&#xea8a",
  "grid-filled" = "&#xea8c",
  "grid" = "&#xea73",
  "hard-drive-filled" = "&#xea8e",
  "hard-drive" = "&#xea8d",
  "hashtag-circle-filled" = "&#xedc5",
  "hashtag-circle" = "&#xedc4",
  "hashtag-filled" = "&#xedc6",
  "hashtag-rec-filled" = "&#xedc7",
  "hashtag-rec" = "&#xea8f",
  "hashtag" = "&#xedc3",
  "headphones-2-filled" = "&#xedc8",
  "headphones-2" = "&#xea91",
  "headphones-filled" = "&#xedc9",
  "headphones" = "&#xea90",
  "heart-filled" = "&#xedcb",
  "heart-slash-filled" = "&#xedcc",
  "heart-slash" = "&#xea92",
  "heart" = "&#xedca",
  "history-filled" = "&#xedce",
  "history-rec-filled" = "&#xedcf",
  "history-rec" = "&#xea93",
  "history" = "&#xedcd",
  "home-2-filled" = "&#xedd0",
  "home-2" = "&#xea95",
  "home-3-filled" = "&#xea97",
  "home-3" = "&#xea96",
  "home-4-filled" = "&#xedd1",
  "home-4" = "&#xea98",
  "home-filled" = "&#xedd2",
  "home" = "&#xea94",
  "iframe-filled" = "&#xedd3",
  "iframe" = "&#xea99",
  "illustrator-filled" = "&#xedd4",
  "illustrator" = "&#xea9a",
  "image-2-filled" = "&#xedd5",
  "image-2" = "&#xea9c",
  "image-3-filled" = "&#xea9e",
  "image-3" = "&#xea9d",
  "image-check-filled" = "&#xeaa0",
  "image-check" = "&#xea9f",
  "image-cross-filled" = "&#xeaa2",
  "image-cross" = "&#xeaa1",
  "image-download-filled" = "&#xeaa4",
  "image-download" = "&#xeaa3",
  "image-edit-filled" = "&#xeaa6",
  "image-edit" = "&#xeaa5",
  "image-filled" = "&#xedd6",
  "image-plus-filled" = "&#xeaa8",
  "image-plus" = "&#xeaa7",
  "image-slash-filled" = "&#xeaaa",
  "image-slash" = "&#xeaa9",
  "image-upload-filled" = "&#xeaac",
  "image-upload" = "&#xeaab",
  "image" = "&#xea9b",
  "import-2-filled" = "&#xedd8",
  "import-2" = "&#xeaad",
  "import-3-filled" = "&#xedda",
  "import-3" = "&#xedd9",
  "import-4-filled" = "&#xeddc",
  "import-4" = "&#xeddb",
  "import-filled" = "&#xeddd",
  "import" = "&#xedd7",
  "inbox-filled-filled" = "&#xeab0",
  "inbox-filled" = "&#xeaaf",
  "inbox-in-filled" = "&#xeab2",
  "inbox-in" = "&#xeab1",
  "inbox-out-filled" = "&#xeab4",
  "inbox-out" = "&#xeab3",
  "inbox" = "&#xeaae",
  "indent-decrease-filled" = "&#xeddf",
  "indent-decrease-rec-filled" = "&#xede0",
  "indent-decrease-rec" = "&#xeab5",
  "indent-decrease" = "&#xedde",
  "indent-first-line-filled" = "&#xede2",
  "indent-first-line-rec-filled" = "&#xede3",
  "indent-first-line-rec" = "&#xeab6",
  "indent-first-line" = "&#xede1",
  "indent-increase-filled" = "&#xede5",
  "indent-increase-rec-filled" = "&#xede6",
  "indent-increase-rec" = "&#xeab7",
  "indent-increase" = "&#xede4",
  "infinity-filled" = "&#xede8",
  "infinity-rec-filled" = "&#xede9",
  "infinity-rec" = "&#xeab8",
  "infinity" = "&#xede7",
  "info-filled" = "&#xedeb",
  "info" = "&#xedea",
  "instagram-filled" = "&#xedec",
  "instagram" = "&#xeab9",
  "invoice-filled" = "&#xeabb",
  "invoice" = "&#xeaba",
  "kanban-filled" = "&#xeded",
  "kanban" = "&#xeabc",
  "key-filled" = "&#xedf0",
  "key" = "&#xedee",
  "keyboard-filled" = "&#xedef",
  "keyboard-hide-filled" = "&#xeabf",
  "keyboard-hide" = "&#xeabe",
  "keyboard-show-filled" = "&#xeac1",
  "keyboard-show" = "&#xeac0",
  "keyboard" = "&#xeabd",
  "label-4-k-filled" = "&#xedf1",
  "label-4-k" = "&#xeac2",
  "label-explicit-filled" = "&#xedf2",
  "label-explicit" = "&#xeac3",
  "label-hd-filled" = "&#xeac5",
  "label-hd" = "&#xeac4",
  "label-hq-filled" = "&#xedf3",
  "label-hq" = "&#xeac6",
  "laptop-filled" = "&#xedf5",
  "laptop" = "&#xedf4",
  "layers-filled" = "&#xeac8",
  "layers" = "&#xeac7",
  "letter-spacing-2-filled" = "&#xedf8",
  "letter-spacing-2-rec-filled" = "&#xedf9",
  "letter-spacing-2-rec" = "&#xeac9",
  "letter-spacing-2" = "&#xedf7",
  "letter-spacing-filled" = "&#xedfa",
  "letter-spacing-rec-filled" = "&#xedfb",
  "letter-spacing-rec" = "&#xeaca",
  "letter-spacing" = "&#xedf6",
  "lifebuoy-filled" = "&#xeacc",
  "lifebuoy" = "&#xeacb",
  "lightning-filled" = "&#xedfc",
  "lightning-slash-filled" = "&#xeacf",
  "lightning-slash" = "&#xeace",
  "lightning" = "&#xeacd",
  "line-height-2-filled" = "&#xedff",
  "line-height-2-rec-filled" = "&#xee00",
  "line-height-2-rec" = "&#xead0",
  "line-height-2" = "&#xedfe",
  "line-height-filled" = "&#xee01",
  "line-height-rec-filled" = "&#xee02",
  "line-height-rec" = "&#xead1",
  "line-height" = "&#xedfd",
  "link-1-filled" = "&#xee04",
  "link-1-rec-filled" = "&#xee05",
  "link-1-rec" = "&#xead2",
  "link-1" = "&#xee03",
  "link-2-filled" = "&#xee07",
  "link-2-rec-filled" = "&#xead4",
  "link-2-rec" = "&#xead3",
  "link-2" = "&#xee06",
  "linkedin-filled" = "&#xee08",
  "linkedin" = "&#xead5",
  "list-ordered-filled" = "&#xee0a",
  "list-ordered-rec-filled" = "&#xee0b",
  "list-ordered-rec" = "&#xead6",
  "list-ordered" = "&#xee09",
  "list-unordered-2-filled" = "&#xead8",
  "list-unordered-2-rec-filled" = "&#xee0d",
  "list-unordered-2-rec" = "&#xead9",
  "list-unordered-2" = "&#xead7",
  "list-unordered-3-filled" = "&#xee0f",
  "list-unordered-3" = "&#xee0e",
  "list-unordered-4-filled" = "&#xee11",
  "list-unordered-4-rec-filled" = "&#xee12",
  "list-unordered-4-rec" = "&#xeada",
  "list-unordered-4" = "&#xee10",
  "list-unordered-5-filled" = "&#xee14",
  "list-unordered-5-rec-filled" = "&#xeadc",
  "list-unordered-5-rec" = "&#xeadb",
  "list-unordered-5" = "&#xee13",
  "list-unordered-filled" = "&#xee15",
  "list-unordered-rec-filled" = "&#xee16",
  "list-unordered-rec" = "&#xeadd",
  "list-unordered" = "&#xee0c",
  "loader-2-filled" = "&#xee19",
  "loader-2-rec-filled" = "&#xee1a",
  "loader-2-rec" = "&#xeade",
  "loader-2" = "&#xee18",
  "loader-filled" = "&#xee1b",
  "loader-rec-filled" = "&#xeae0",
  "loader-rec" = "&#xeadf",
  "loader" = "&#xee17",
  "loading-trolley-filled" = "&#xee1c",
  "loading-trolley" = "&#xeae1",
  "lock-2-filled" = "&#xeae3",
  "lock-2-opened-filled" = "&#xee1e",
  "lock-2-opened" = "&#xeae4",
  "lock-2-slash-filled" = "&#xeae6",
  "lock-2-slash" = "&#xeae5",
  "lock-2" = "&#xeae2",
  "lock-filled" = "&#xee1f",
  "lock-opened-filled" = "&#xee21",
  "lock-opened" = "&#xee20",
  "lock-slash-filled" = "&#xee23",
  "lock-slash" = "&#xee22",
  "lock" = "&#xee1d",
  "log-in-2-filled" = "&#xee25",
  "log-in-2" = "&#xeae7",
  "log-in-3-filled" = "&#xee27",
  "log-in-3" = "&#xee26",
  "log-in-filled" = "&#xee28",
  "log-in" = "&#xee24",
  "log-out-2-filled" = "&#xee2a",
  "log-out-2" = "&#xeae8",
  "log-out-3-filled" = "&#xee2c",
  "log-out-3" = "&#xee2b",
  "log-out-filled" = "&#xee2d",
  "log-out" = "&#xee29",
  "map-view-filled" = "&#xeaea",
  "map-view" = "&#xeae9",
  "maximize-2-filled" = "&#xee30",
  "maximize-2-flipped-filled" = "&#xee32",
  "maximize-2-flipped-rec-filled" = "&#xee33",
  "maximize-2-flipped-rec" = "&#xeaeb",
  "maximize-2-flipped" = "&#xee31",
  "maximize-2-rec-filled" = "&#xee34",
  "maximize-2-rec" = "&#xeaec",
  "maximize-2" = "&#xee2f",
  "maximize-3-filled" = "&#xeaee",
  "maximize-3-rec-filled" = "&#xeaf0",
  "maximize-3-rec" = "&#xeaef",
  "maximize-3" = "&#xeaed",
  "maximize-filled" = "&#xee35",
  "maximize-flipped-filled" = "&#xee37",
  "maximize-flipped-rec-filled" = "&#xee38",
  "maximize-flipped-rec" = "&#xeaf1",
  "maximize-flipped" = "&#xee36",
  "maximize-rec-filled" = "&#xee39",
  "maximize-rec" = "&#xeaf2",
  "maximize" = "&#xee2e",
  "megaphone-filled" = "&#xee3a",
  "megaphone" = "&#xeaf3",
  "memory-card-filled" = "&#xee3b",
  "memory-card" = "&#xeaf4",
  "menu-2-filled" = "&#xee3e",
  "menu-2-rec-filled" = "&#xee3f",
  "menu-2-rec" = "&#xeaf5",
  "menu-2" = "&#xee3d",
  "menu-filled" = "&#xee40",
  "menu-rec-filled" = "&#xee41",
  "menu-rec" = "&#xeaf6",
  "menu" = "&#xee3c",
  "messanger-filled" = "&#xee43",
  "messanger" = "&#xee42",
  "mic-filled" = "&#xee45",
  "mic-slash-filled" = "&#xee47",
  "mic-slash" = "&#xee46",
  "mic" = "&#xee44",
  "minimize-filled" = "&#xeaf8",
  "minimize-rec-filled" = "&#xeafa",
  "minimize-rec" = "&#xeaf9",
  "minimize" = "&#xeaf7",
  "miniplayer-filled" = "&#xee48",
  "miniplayer" = "&#xeafb",
  "minus-circle-filled" = "&#xee4b",
  "minus-circle" = "&#xee4a",
  "minus-filled" = "&#xee4c",
  "minus-octagon-filled" = "&#xee4d",
  "minus-octagon" = "&#xeafc",
  "minus-rec-filled" = "&#xee4e",
  "minus-rec" = "&#xeafd",
  "minus" = "&#xee49",
  "modules-filled" = "&#xee50",
  "modules" = "&#xee4f",
  "monitor-filled" = "&#xeaff",
  "monitor" = "&#xeafe",
  "moon-filled" = "&#xee52",
  "moon" = "&#xee51",
  "more-horiz-filled" = "&#xee54",
  "more-horiz-rec-filled" = "&#xee55",
  "more-horiz-rec" = "&#xeb00",
  "more-horiz" = "&#xee53",
  "more-vert-filled" = "&#xee57",
  "more-vert-rec-filled" = "&#xee58",
  "more-vert-rec" = "&#xeb01",
  "more-vert" = "&#xee56",
  "mouse-filled" = "&#xee5a",
  "mouse" = "&#xee59",
  "music-library-filled" = "&#xeb03",
  "music-library" = "&#xeb02",
  "music-note-2-filled" = "&#xee5d",
  "music-note-2" = "&#xee5c",
  "music-note-3-filled" = "&#xee5e",
  "music-note-3" = "&#xeb04",
  "music-note-filled" = "&#xee5f",
  "music-note" = "&#xee5b",
  "music-plus-filled" = "&#xeb06",
  "music-plus" = "&#xeb05",
  "music-queue-filled" = "&#xeb08",
  "music-queue" = "&#xeb07",
  "new-release-filled" = "&#xeb0a",
  "new-release" = "&#xeb09",
  "newspaper-filled" = "&#xeb0c",
  "newspaper" = "&#xeb0b",
  "note-2-filled" = "&#xee60",
  "note-2-text-filled" = "&#xee61",
  "note-2-text" = "&#xeb0f",
  "note-2" = "&#xeb0e",
  "note-filled" = "&#xee63",
  "note-text-filled" = "&#xee64",
  "note-text" = "&#xeb11",
  "note" = "&#xeb0d",
  "notebook-filled" = "&#xee62",
  "notebook" = "&#xeb10",
  "notification-indicator-filled" = "&#xee65",
  "notification-indicator" = "&#xeb12",
  "notion-filled" = "&#xeb14",
  "notion" = "&#xeb13",
  "nut-filled" = "&#xee66",
  "nut" = "&#xeb15",
  "padding-filled" = "&#xee67",
  "padding" = "&#xeb16",
  "page-break-filled" = "&#xeb18",
  "page-break" = "&#xeb17",
  "paint-bucket-filled" = "&#xeb1a",
  "paint-bucket" = "&#xeb19",
  "paint-tool-filled" = "&#xeb1c",
  "paint-tool" = "&#xeb1b",
  "paperclip-2-filled" = "&#xee6a",
  "paperclip-2-rec-filled" = "&#xee6b",
  "paperclip-2-rec" = "&#xeb1d",
  "paperclip-2-slash-filled" = "&#xee6d",
  "paperclip-2-slash-rec-filled" = "&#xee6e",
  "paperclip-2-slash-rec" = "&#xeb1e",
  "paperclip-2-slash" = "&#xee6c",
  "paperclip-2" = "&#xee69",
  "paperclip-filled" = "&#xee6f",
  "paperclip-rec-filled" = "&#xee70",
  "paperclip-rec" = "&#xeb1f",
  "paperclip-slash-filled" = "&#xee72",
  "paperclip-slash-rec-filled" = "&#xeb21",
  "paperclip-slash-rec" = "&#xeb20",
  "paperclip-slash" = "&#xee71",
  "paperclip" = "&#xee68",
  "pause-circle-filled" = "&#xee74",
  "pause-circle" = "&#xee73",
  "pause-filled" = "&#xee75",
  "pause" = "&#xeb22",
  "pdf" = "&#xef98",
  "pending-review" = "&#xefa0",
  "pen-tool-2-filled" = "&#xee76",
  "pen-tool-2" = "&#xeb24",
  "pen-tool-filled" = "&#xee77",
  "pen-tool" = "&#xeb23",
  "pharagraph-spacing-filled" = "&#xee79",
  "pharagraph-spacing-rec-filled" = "&#xee7a",
  "pharagraph-spacing-rec" = "&#xeb25",
  "pharagraph-spacing" = "&#xee78",
  "phone-filled" = "&#xeb27",
  "phone" = "&#xeb26",
  "photoshop-filled" = "&#xeb29",
  "photoshop" = "&#xeb28",
  "pin-2-filled" = "&#xee7d",
  "pin-2" = "&#xee7c",
  "pin-3-filled" = "&#xeb2b",
  "pin-3" = "&#xeb2a",
  "pin-check-filled" = "&#xee7f",
  "pin-check" = "&#xee7e",
  "pin-cross-filled" = "&#xee81",
  "pin-cross" = "&#xee80",
  "pin-filled" = "&#xee82",
  "pin-minus-filled" = "&#xee84",
  "pin-minus" = "&#xee83",
  "pin-plus-filled" = "&#xee86",
  "pin-plus" = "&#xee85",
  "pin-slash-filled" = "&#xee87",
  "pin-slash" = "&#xeb2c",
  "pin" = "&#xee7b",
  "pinterest-filled" = "&#xee89",
  "pinterest" = "&#xee88",
  "planet-filled" = "&#xeb2e",
  "planet" = "&#xeb2d",
  "play-circle-filled" = "&#xee8b",
  "play-circle" = "&#xee8a",
  "play-filled" = "&#xee8c",
  "play-next-filled" = "&#xee93",
  "play-next" = "&#xeb30",
  "play-previous-filled" = "&#xee94",
  "play-previous" = "&#xeb31",
  "play-slowmotion-filled" = "&#xeb33",
  "play-slowmotion" = "&#xeb32",
  "play" = "&#xeb2f",
  "playlist-filled" = "&#xee8e",
  "playlist-play-filled" = "&#xee90",
  "playlist-play" = "&#xee8f",
  "playlist-plus-filled" = "&#xee92",
  "playlist-plus" = "&#xee91",
  "playlist" = "&#xee8d",
  "plug-filled" = "&#xee95",
  "plug-slash-filled" = "&#xeb36",
  "plug-slash" = "&#xeb35",
  "plug" = "&#xeb34",
  "plus-circle-filled" = "&#xee98",
  "plus-circle" = "&#xee97",
  "plus-filled" = "&#xee99",
  "plus-rec-filled" = "&#xee9a",
  "plus-rec" = "&#xeb37",
  "plus" = "&#xee96",
  "pos-filled" = "&#xeb39",
  "pos" = "&#xeb38",
  "power-filled" = "&#xee9c",
  "power-rec-filled" = "&#xee9d",
  "power-rec" = "&#xeb3a",
  "power" = "&#xee9b",
  "presentation-chart-filled" = "&#xee9e",
  "presentation-chart" = "&#xeb3b",
  "presentation-line-filled" = "&#xee9f",
  "presentation-line" = "&#xeb3c",
  "printer-filled" = "&#xeb3e",
  "printer-slash-filled" = "&#xeb40",
  "printer-slash" = "&#xeb3f",
  "printer" = "&#xeb3d",
  "procent-circle-filled" = "&#xeea2",
  "procent-circle" = "&#xeea1",
  "procent-filled" = "&#xeea3",
  "procent" = "&#xeea0",
  "processor-filled" = "&#xeb42",
  "processor" = "&#xeb41",
  "producthunt-filled" = "&#xeea5",
  "producthunt" = "&#xeea4",
  "question-filled" = "&#xeea7",
  "question" = "&#xeea6",
  "quotes-2-filled" = "&#xeb45",
  "quotes-2" = "&#xeb44",
  "quotes-filled" = "&#xeb46",
  "quotes" = "&#xeb43",
  "radio-filled" = "&#xeea8",
  "radio" = "&#xeb47",
  "receipt-filled" = "&#xeb49",
  "receipt" = "&#xeb48",
  "record-filled" = "&#xeeaa",
  "record" = "&#xeea9",
  "redo-2-filled" = "&#xeead",
  "redo-2-rec-filled" = "&#xeeae",
  "redo-2-rec" = "&#xeb4a",
  "redo-2" = "&#xeeac",
  "redo-filled" = "&#xeeaf",
  "redo-rec-filled" = "&#xeeb0",
  "redo-rec" = "&#xeb4b",
  "redo" = "&#xeeab",
  "refresh-ccw-alert-filled" = "&#xeeb3",
  "refresh-ccw-alert-rec-filled" = "&#xeb4d",
  "refresh-ccw-alert-rec" = "&#xeb4c",
  "refresh-ccw-alert" = "&#xeeb2",
  "refresh-ccw-filled" = "&#xeeb4",
  "refresh-ccw-rec-filled" = "&#xeb4f",
  "refresh-ccw-rec" = "&#xeb4e",
  "refresh-ccw" = "&#xeeb1",
  "refresh-cw-alert-filled" = "&#xeeb7",
  "refresh-cw-alert-rec-filled" = "&#xeb51",
  "refresh-cw-alert-rec" = "&#xeb50",
  "refresh-cw-alert" = "&#xeeb6",
  "refresh-cw-filled" = "&#xeeb8",
  "refresh-cw-rec-filled" = "&#xeb53",
  "refresh-cw-rec" = "&#xeb52",
  "refresh-cw" = "&#xeeb5",
  "reorder-filled" = "&#xef9d",
  "reorder" = "&#xef9c",
  "repeat-filled" = "&#xeb55",
  "repeat-on-filled" = "&#xeb57",
  "repeat-on" = "&#xeb56",
  "repeat" = "&#xeb54",
  "replay-10-filled" = "&#xeebe",
  "replay-10-rec-filled" = "&#xeebf",
  "replay-10-rec" = "&#xeb59",
  "replay-10" = "&#xeebd",
  "replay-15-filled" = "&#xeec1",
  "replay-15-rec-filled" = "&#xeec2",
  "replay-15-rec" = "&#xeb5a",
  "replay-15" = "&#xeec0",
  "replay-5-filled" = "&#xeebb",
  "replay-5-rec-filled" = "&#xeebc",
  "replay-5-rec" = "&#xeb58",
  "replay-5" = "&#xeeba",
  "replay-filled" = "&#xeec3",
  "replay-rec-filled" = "&#xeec4",
  "replay-rec" = "&#xeb5b",
  "replay" = "&#xeeb9",
  "reply-all-filled" = "&#xeec7",
  "reply-all-rec-filled" = "&#xeec8",
  "reply-all-rec" = "&#xeb5c",
  "reply-all" = "&#xeec6",
  "reply-filled" = "&#xeec9",
  "reply-rec-filled" = "&#xeeca",
  "reply-rec" = "&#xeb5d",
  "reply" = "&#xeec5",
  "rocket-2-filled" = "&#xeb60",
  "rocket-2" = "&#xeb5f",
  "rocket-filled" = "&#xeb61",
  "rocket" = "&#xeb5e",
  "rotate-ccw-filled" = "&#xeecc",
  "rotate-ccw-rec-filled" = "&#xeecd",
  "rotate-ccw-rec" = "&#xeb62",
  "rotate-ccw" = "&#xeecb",
  "rotate-cw-filled" = "&#xeecf",
  "rotate-cw-rec-filled" = "&#xeed0",
  "rotate-cw-rec" = "&#xeb63",
  "rotate-cw" = "&#xeece",
  "route-filled" = "&#xeed2",
  "route" = "&#xeed1",
  "ruler-filled" = "&#xeed3",
  "ruler" = "&#xeb64",
  "scale-filled" = "&#xeed4",
  "scale" = "&#xeb65",
  "scan-filled" = "&#xeed6",
  "scan-rec-filled" = "&#xeb67",
  "scan-rec" = "&#xeb66",
  "scan" = "&#xeed5",
  "script-filled" = "&#xeed8",
  "script-rec-filled" = "&#xeed9",
  "script-rec" = "&#xeb68",
  "script" = "&#xeed7",
  "search-filled" = "&#xeedb",
  "search" = "&#xeeda",
  "selection-filled" = "&#xeb6a",
  "selection-rec-filled" = "&#xeb6c",
  "selection-rec" = "&#xeb6b",
  "selection" = "&#xeb69",
  "send-2-filled" = "&#xeedc",
  "send-2" = "&#xeb6e",
  "send-filled" = "&#xeedd",
  "send" = "&#xeb6d",
  "server-2-filled" = "&#xeb71",
  "server-2" = "&#xeb70",
  "server-filled" = "&#xeede",
  "server" = "&#xeb6f",
  "share-filled" = "&#xeee0",
  "share" = "&#xeedf",
  "shield-check-filled" = "&#xeee1",
  "shield-check" = "&#xeb73",
  "shield-cross-filled" = "&#xeee2",
  "shield-cross" = "&#xeb74",
  "shield-filled" = "&#xeb75",
  "shield" = "&#xeb72",
  "shopping-bag-2-filled" = "&#xeee3",
  "shopping-bag-2" = "&#xeb77",
  "shopping-bag-filled" = "&#xeee4",
  "shopping-bag" = "&#xeb76",
  "shopping-basket-filled" = "&#xeee5",
  "shopping-basket" = "&#xeb78",
  "shopping-cart-2-filled" = "&#xeee6",
  "shopping-cart-2" = "&#xeb7a",
  "shopping-cart-filled" = "&#xeee7",
  "shopping-cart" = "&#xeb79",
  "shuffle-filled" = "&#xeb7b",
  "shuffle" = "&#xeee8",
  "sketch-filled" = "&#xeee9",
  "sketch" = "&#xeb7c",
  "slash-filled" = "&#xeeeb",
  "slash" = "&#xeeea",
  "slider-horizontal-2-filled" = "&#xeeec",
  "slider-horizontal-2" = "&#xeb7e",
  "slider-horizontal-filled" = "&#xeeed",
  "slider-horizontal" = "&#xeb7d",
  "slider-vertical-2-filled" = "&#xeef4",
  "slider-vertical-2" = "&#xeb82",
  "slider-vertical-filled" = "&#xeef5",
  "slider-vertical" = "&#xeb81",
  "sliders-horiz-2-filled" = "&#xeeef",
  "sliders-horiz-2" = "&#xeb7f",
  "sliders-horiz-filled" = "&#xeef0",
  "sliders-horiz" = "&#xeeee",
  "sliders-vert-2-filled" = "&#xeef2",
  "sliders-vert-2" = "&#xeb80",
  "sliders-vert-filled" = "&#xeef3",
  "sliders-vert" = "&#xeef1",
  "small-caps-2-filled" = "&#xeef8",
  "small-caps-2-rec-filled" = "&#xeef9",
  "small-caps-2-rec" = "&#xeb83",
  "small-caps-2" = "&#xeef7",
  "small-caps-filled" = "&#xeefa",
  "small-caps-rec-filled" = "&#xeb85",
  "small-caps-rec" = "&#xeb84",
  "small-caps" = "&#xeef6",
  "smartphone-filled" = "&#xeefb",
  "smartphone" = "&#xeb86",
  "sora-logo" = "&#xef97",
  "sort-number-filled" = "&#xeb88",
  "sort-number-rec-filled" = "&#xeefc",
  "sort-number-rec" = "&#xeb89",
  "sort-number-reverse-filled" = "&#xeb8b",
  "sort-number-reverse-rec-filled" = "&#xeb8d",
  "sort-number-reverse-rec" = "&#xeb8c",
  "sort-number-reverse" = "&#xeb8a",
  "sort-number" = "&#xeb87",
  "sort-text-filled" = "&#xeefe",
  "sort-text-rec-filled" = "&#xeeff",
  "sort-text-rec" = "&#xeb8e",
  "sort-text-reverse-filled" = "&#xef01",
  "sort-text-reverse-rec-filled" = "&#xef02",
  "sort-text-reverse-rec" = "&#xeb8f",
  "sort-text-reverse" = "&#xef00",
  "sort-text" = "&#xeefd",
  "soundcloud-filled" = "&#xef04",
  "soundcloud" = "&#xef03",
  "soundwave-filled" = "&#xef06",
  "soundwave-rec-filled" = "&#xef07",
  "soundwave-rec" = "&#xeb90",
  "soundwave" = "&#xef05",
  "sparkles-filled" = "&#xefa3",
  "sparkles" = "&#xefa4",
  "speaker-filled" = "&#xef08",
  "speaker" = "&#xeb91",
  "special-charachers-filled" = "&#xef0a",
  "special-charachers-rec-filled" = "&#xef0b",
  "special-charachers-rec" = "&#xeb92",
  "special-charachers" = "&#xef09",
  "speed-filled" = "&#xef0d",
  "speed-rec-filled" = "&#xef0e",
  "speed-rec" = "&#xeb93",
  "speed" = "&#xef0c",
  "spotify-filled" = "&#xef10",
  "spotify" = "&#xef0f",
  "stack-filled" = "&#xeb95",
  "stack" = "&#xeb94",
  "star-filled" = "&#xeb97",
  "star-slash-filled" = "&#xeb99",
  "star-slash" = "&#xeb98",
  "star" = "&#xeb96",
  "sticker-filled" = "&#xef12",
  "sticker" = "&#xef11",
  "stop-circle-filled" = "&#xef14",
  "stop-circle" = "&#xeb9a",
  "stop-filled" = "&#xef15",
  "stop" = "&#xef13",
  "stopwatch-filled" = "&#xef17",
  "stopwatch-slash-filled" = "&#xef19",
  "stopwatch-slash" = "&#xef18",
  "stopwatch" = "&#xef16",
  "sun-filled" = "&#xef1b",
  "sun" = "&#xef1a",
  "sunrise-filled" = "&#xef1d",
  "sunrise" = "&#xef1c",
  "sunset-filled" = "&#xef1f",
  "sunset" = "&#xef1e",
  "swatchbook-filled" = "&#xeb9c",
  "swatchbook" = "&#xeb9b",
  "tablet-filled" = "&#xef20",
  "tablet" = "&#xeb9d",
  "tag-2-filled" = "&#xef21",
  "tag-2" = "&#xeb9f",
  "tag-filled" = "&#xef22",
  "tag" = "&#xeb9e",
  "tags-2-filled" = "&#xeba2",
  "tags-2" = "&#xeba1",
  "tags-filled" = "&#xeba3",
  "tags" = "&#xeba0",
  "target-filled" = "&#xef24",
  "target" = "&#xef23",
  "terminal-filled" = "&#xef25",
  "terminal" = "&#xeba4",
  "text-2-filled" = "&#xef28",
  "text-2-rec-filled" = "&#xef29",
  "text-2-rec" = "&#xeba5",
  "text-2" = "&#xef27",
  "text-block-filled" = "&#xef2a",
  "text-block" = "&#xeba6",
  "text-bold-filled" = "&#xef2c",
  "text-bold-rec-filled" = "&#xeba8",
  "text-bold-rec" = "&#xeba7",
  "text-bold" = "&#xef2b",
  "text-filled" = "&#xef2d",
  "text-format-filled" = "&#xef2f",
  "text-format" = "&#xef2e",
  "text-italic-filled" = "&#xef31",
  "text-italic-rec-filled" = "&#xef32",
  "text-italic-rec" = "&#xeba9",
  "text-italic" = "&#xef30",
  "text-rec-filled" = "&#xef33",
  "text-rec" = "&#xebaa",
  "text-slash-2-filled" = "&#xef36",
  "text-slash-2-rec-filled" = "&#xef37",
  "text-slash-2-rec" = "&#xebab",
  "text-slash-2" = "&#xef35",
  "text-slash-filled" = "&#xef38",
  "text-slash-rec-filled" = "&#xef39",
  "text-slash-rec" = "&#xebac",
  "text-slash" = "&#xef34",
  "text-strikethrough-filled" = "&#xef3b",
  "text-strikethrough-rec-filled" = "&#xef3c",
  "text-strikethrough-rec" = "&#xebad",
  "text-strikethrough" = "&#xef3a",
  "text-underline-filled" = "&#xef3e",
  "text-underline-rec-filled" = "&#xef3f",
  "text-underline-rec" = "&#xebae",
  "text-underline" = "&#xef3d",
  "text" = "&#xef26",
  "thermometer-filled" = "&#xef41",
  "thermometer" = "&#xef40",
  "thumbs-down-2-filled" = "&#xef42",
  "thumbs-down-2" = "&#xebb0",
  "thumbs-down-filled" = "&#xef43",
  "thumbs-down" = "&#xebaf",
  "thumbs-up-2-filled" = "&#xef44",
  "thumbs-up-2" = "&#xebb2",
  "thumbs-up-filled" = "&#xef45",
  "thumbs-up" = "&#xebb1",
  "ticket-filled" = "&#xef46",
  "ticket" = "&#xebb3",
  "time-filled" = "&#xef48",
  "time" = "&#xef47",
  "timer-2-filled" = "&#xef4a",
  "timer-2-rec-filled" = "&#xef4b",
  "timer-2-rec" = "&#xebb5",
  "timer-2" = "&#xef49",
  "timer-filled" = "&#xef4c",
  "timer" = "&#xebb4",
  "toggle-off-filled" = "&#xef4e",
  "toggle-off" = "&#xef4d",
  "toggle-on-filled" = "&#xef50",
  "toggle-on" = "&#xef4f",
  "transform-filled" = "&#xef52",
  "transform" = "&#xef51",
  "trash-2-filled" = "&#xef53",
  "trash-2" = "&#xebb7",
  "trash-filled" = "&#xebb8",
  "trash" = "&#xebb6",
  "trend-down-filled" = "&#xef55",
  "trend-down-rec-filled" = "&#xef56",
  "trend-down-rec" = "&#xebb9",
  "trend-down" = "&#xef54",
  "trend-up-filled" = "&#xef58",
  "trend-up-rec-filled" = "&#xef59",
  "trend-up-rec" = "&#xebba",
  "trend-up" = "&#xef57",
  "trophy-filled" = "&#xebbc",
  "trophy" = "&#xebbb",
  "truck-filled" = "&#xebbe",
  "truck" = "&#xebbd",
  "tv-filled" = "&#xef5a",
  "tv" = "&#xebbf",
  "twitter-filled" = "&#xef5b",
  "twitter" = "&#xebc0",
  "umbrella-filled" = "&#xef5c",
  "umbrella" = "&#xebc1",
  "undo-2-filled" = "&#xef5f",
  "undo-2-rec-filled" = "&#xebc3",
  "undo-2-rec" = "&#xebc2",
  "undo-2" = "&#xef5e",
  "undo-filled" = "&#xef60",
  "undo-rec-filled" = "&#xef61",
  "undo-rec" = "&#xebc4",
  "undo" = "&#xef5d",
  "user-alert-filled" = "&#xef64",
  "user-alert" = "&#xef63",
  "user-arrow-bl-filled" = "&#xef66",
  "user-arrow-bl" = "&#xef65",
  "user-arrow-bottom-filled" = "&#xef68",
  "user-arrow-bottom" = "&#xef67",
  "user-arrow-right-filled" = "&#xef6a",
  "user-arrow-right" = "&#xef69",
  "user-arrow-top-filled" = "&#xef6c",
  "user-arrow-top" = "&#xef6b",
  "user-arrow-tr-filled" = "&#xef6e",
  "user-arrow-tr" = "&#xef6d",
  "user-check-filled" = "&#xef70",
  "user-check" = "&#xef6f",
  "user-circle-filled" = "&#xef72",
  "user-circle" = "&#xef71",
  "user-cross-filled" = "&#xef74",
  "user-cross" = "&#xef73",
  "user-filled" = "&#xef75",
  "user-minus-filled" = "&#xef77",
  "user-minus" = "&#xef76",
  "user-plus-filled" = "&#xef79",
  "user-plus" = "&#xef78",
  "user-square-filled" = "&#xef7c",
  "user-square" = "&#xebc5",
  "user" = "&#xef62",
  "users-filled" = "&#xef7b",
  "users" = "&#xef7a",
  "vector-filled" = "&#xef7e",
  "vector" = "&#xef7d",
  "verified-filled" = "&#xebc7",
  "verified" = "&#xebc6",
  "video-camera-filled" = "&#xef7f",
  "video-camera-slash-filled" = "&#xebca",
  "video-camera-slash" = "&#xebc9",
  "video-camera" = "&#xebc8",
  "video-library-filled" = "&#xebcc",
  "video-library" = "&#xebcb",
  "video-plus-filled" = "&#xebce",
  "video-plus" = "&#xebcd",
  "video-queue-filled" = "&#xebd0",
  "video-queue" = "&#xebcf",
  "voicemail-filled" = "&#xef81",
  "voicemail" = "&#xef80",
  "volume-cross-filled" = "&#xef82",
  "volume-cross" = "&#xebd1",
  "volume-high-filled" = "&#xebd3",
  "volume-high" = "&#xebd2",
  "volume-low-filled" = "&#xef83",
  "volume-low" = "&#xebd4",
  "volume-mute-filled" = "&#xef84",
  "volume-mute" = "&#xebd5",
  "volume-slash-filled" = "&#xebd7",
  "volume-slash" = "&#xebd6",
  "wallet-filled" = "&#xef85",
  "wallet-minus-filled" = "&#xebda",
  "wallet-minus" = "&#xebd9",
  "wallet-move-filled" = "&#xebdc",
  "wallet-move" = "&#xebdb",
  "wallet-plus-filled" = "&#xebde",
  "wallet-plus" = "&#xebdd",
  "wallet" = "&#xebd8",
  "watch-2-filled" = "&#xebe1",
  "watch-2" = "&#xebe0",
  "watch-filled" = "&#xebe2",
  "watch" = "&#xebdf",
  "webcam-filled" = "&#xef86",
  "webcam-slash-filled" = "&#xef87",
  "webcam-slash" = "&#xebe4",
  "webcam" = "&#xebe3",
  "weight-filled" = "&#xef88",
  "weight" = "&#xebe5",
  "weighter-2-filled" = "&#xebe8",
  "weighter-2" = "&#xebe7",
  "weighter-filled" = "&#xebe9",
  "weighter" = "&#xebe6",
  "wheelchair-filled" = "&#xebeb",
  "wheelchair-rec-filled" = "&#xebed",
  "wheelchair-rec" = "&#xebec",
  "wheelchair" = "&#xebea",
  "wifi-filled" = "&#xef8a",
  "wifi-rec-filled" = "&#xebef",
  "wifi-rec" = "&#xebee",
  "wifi-slash-filled" = "&#xebf1",
  "wifi-slash-rec-filled" = "&#xebf3",
  "wifi-slash-rec" = "&#xebf2",
  "wifi-slash" = "&#xebf0",
  "wifi" = "&#xef89",
  "windows-filled" = "&#xef8b",
  "windows" = "&#xebf4",
  "wrap-text-filled" = "&#xef8d",
  "wrap-text-rec-filled" = "&#xef8e",
  "wrap-text-rec" = "&#xebf5",
  "wrap-text" = "&#xef8c",
  "wrench-filled" = "&#xef8f",
  "wrench" = "&#xebf6",
  "yin-yang-filled" = "&#xef91",
  "yin-yang" = "&#xef90",
  "youtube-filled" = "&#xef92",
  "youtube" = "&#xebf7",
  "zoom-in-filled" = "&#xef94",
  "zoom-in" = "&#xef93",
  "zoom-out-filled" = "&#xef96",
  "zoom-out" = "&#xef95",
}
