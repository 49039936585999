import React from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '@design-system'
import SoraLogo from './sora_logo.svg'
import SoraLogoDarkMode from './sora_logo_white.svg'

const logoVariants = cva(
  '',
  {
    variants: {
      /** Size of the logo */
      size: {
        xs: 'w-[5.875rem]',
        sm: 'w-24',
        md: 'w-32',
        lg: 'w-44',
        xl: 'w-64',
      },
    },
    defaultVariants: {
      size: 'xs',
    },
  }
)

export interface LogoProps extends React.HTMLAttributes<HTMLImageElement>, VariantProps<typeof logoVariants> {
}

/**
 * Sora Schools with support for dark mode.
 */
export const Logo = ({ className, size, ...props }: LogoProps): JSX.Element => (
  <>
    <img
      src={SoraLogo}
      width={192}
      height={76}
      alt="Sora written in black."
      aria-label="Logo"
      data-testid="logo"
      className={cn("dark:hidden", logoVariants({ size, className }))}
      {...props}
    />
    <img
      src={SoraLogoDarkMode}
      width={192}
      height={76}
      aria-label="Logo"
      data-testid="logo-dark"
      alt="Sora written in white."
      className={cn("hidden dark:block", logoVariants({ size, className }))}
      {...props}
    />
  </>
)

Logo.displayName = 'Logo'
