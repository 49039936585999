import React from 'react'

const Icons = {
  bell: () => 'M8 19.5455C8.6586 21.0273 10.3784 22 12 22C13.6216 22 15.3414 21.0273 16 19.5455M18 11.1902V8C18 4.68629 15.3137 2 12 2V2C8.68629 2 6 4.68629 6 8V11.1902C6 12.0239 5.64615 12.8185 5.02647 13.3762L3.91854 14.3733C2.59604 15.5636 3.01797 17.7338 4.69009 18.3419L4.90109 18.4186C9.48681 20.0861 14.5132 20.0861 19.0989 18.4186L19.3099 18.3419C20.982 17.7338 21.404 15.5636 20.0815 14.3733L18.9735 13.3762C18.3539 12.8185 18 12.0239 18 11.1902Z',
  sun: () => 'M11 19L11 21M11 1L11 3M19 11H21M1 11H3M17 16.9995L18.5 18.4995M3.5 3.49949L5.00002 4.99953M17 5L18.5 3.5M3.5 18.5L5 17M16 11C16 13.7614 13.7614 16 11 16C8.23858 16 6 13.7614 6 11C6 8.23858 8.23858 6 11 6C13.7614 6 16 8.23858 16 11Z',
  moon: () => 'M3 11.4489C3 16.7238 7.16904 21 12.3118 21C16.2709 21 19.6529 18.4657 21 14.8925C19.9331 15.4065 18.7418 15.6938 17.485 15.6938C12.9137 15.6938 9.20787 11.8928 9.20787 7.20396C9.20787 5.24299 9.85605 3.4373 10.9446 2C6.45002 2.6783 3 6.65034 3 11.4489Z',
  monitor: () => 'M14 18C14 22 16 22 16 22M16 22H17M16 22H8M7 22H8M2 13V13.2C2 14.8802 2 15.7202 2.32698 16.362C2.6146 16.9265 3.07354 17.3854 3.63803 17.673C4.27976 18 5.11984 18 6.8 18H10M2 13V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V13M2 13H22M22 13V13.2C22 14.8802 22 15.7202 21.673 16.362C21.3854 16.9265 20.9265 17.3854 20.362 17.673C19.7202 18 18.8802 18 17.2 18H10M8 22C8 22 10 21.5 10 18',
  folder: () => 'M9 14H15M15 14L12.5 16.5M15 14L12.5 11.5M6.8 2H8.31672C9.11834 2 9.51916 2 9.88103 2.09146C10.4791 2.24262 11.016 2.57444 11.4186 3.04174C11.6623 3.32451 11.8415 3.683 12.2 4.4V4.4C12.439 4.878 12.5585 5.11699 12.7209 5.30551C12.9894 5.61704 13.3473 5.83825 13.746 5.93902C13.9872 6 14.2544 6 14.7889 6H15.6C17.8402 6 18.9603 6 19.816 6.43597C20.5686 6.81947 21.1805 7.43139 21.564 8.18404C22 9.03968 22 10.1598 22 12.4V15.6C22 17.8402 22 18.9603 21.564 19.816C21.1805 20.5686 20.5686 21.1805 19.816 21.564C18.9603 22 17.8402 22 15.6 22H8.4C6.15979 22 5.03968 22 4.18404 21.564C3.43139 21.1805 2.81947 20.5686 2.43597 19.816C2 18.9603 2 17.8402 2 15.6V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2Z',
  mail: () => 'M18 8L17 8.66667L16.4376 9.0416C14.8338 10.1108 14.0319 10.6454 13.1652 10.8531C12.3992 11.0366 11.6008 11.0366 10.8348 10.8531C9.96808 10.6454 9.16618 10.1108 7.5624 9.0416L7 8.66667L6 8M10 21H14C16.8003 21 18.2004 21 19.27 20.455C20.2108 19.9757 20.9757 19.2108 21.455 18.27C22 17.2004 22 15.8003 22 13V11C22 8.19974 22 6.79961 21.455 5.73005C20.9757 4.78924 20.2108 4.02433 19.27 3.54497C18.2004 3 16.8003 3 14 3H10C7.19974 3 5.79961 3 4.73005 3.54497C3.78924 4.02433 3.02433 4.78924 2.54497 5.73005C2 6.79961 2 8.19974 2 11V13C2 15.8003 2 17.2004 2.54497 18.27C3.02433 19.2108 3.78924 19.9757 4.73005 20.455C5.79961 21 7.19974 21 10 21Z',
  download: () => 'M11.6668 2.08329V3.46665C11.6668 4.58676 11.6668 5.14681 11.8848 5.57463C12.0766 5.95096 12.3825 6.25692 12.7588 6.44866C13.1867 6.66665 13.7467 6.66665 14.8668 6.66665H16.2502M10.0002 9.16663V14.1666M10.0002 14.1666L12.0835 12.0833M10.0002 14.1666L7.91683 12.0833M16.6668 8.77957V11.6666C16.6668 13.2166 16.6668 13.9916 16.4965 14.6274C16.0341 16.3528 14.6864 17.7006 12.9609 18.1629C12.3251 18.3333 11.5501 18.3333 10.0002 18.3333V18.3333C8.45021 18.3333 7.67523 18.3333 7.0394 18.1629C5.31394 17.7006 3.9662 16.3528 3.50387 14.6274C3.3335 13.9916 3.3335 13.2166 3.3335 11.6666V8.14844C3.3335 6.77087 3.3335 6.08208 3.46833 5.51329C3.9044 3.6738 5.34067 2.23753 7.18016 1.80146C7.74895 1.66663 8.43774 1.66663 9.81531 1.66663V1.66663C10.4187 1.66663 10.7204 1.66663 11.0097 1.70045C11.9304 1.80809 12.803 2.16955 13.5302 2.74447C13.7587 2.92512 13.972 3.13845 14.3986 3.5651L14.5835 3.74996C15.1921 4.35856 15.4964 4.66286 15.7378 5.00143C16.1785 5.61948 16.4727 6.32976 16.5981 7.07841C16.6668 7.48853 16.6668 7.91888 16.6668 8.77957Z',
  trash: () => 'M3 4.01221L3.64564 14.988C3.77037 17.1084 3.83273 18.1686 4.28528 18.9732C4.68366 19.6814 5.28832 20.2516 6.01876 20.6077C6.84851 21.0122 7.91054 21.0122 10.0346 21.0122H11.9654C14.0895 21.0122 15.1515 21.0122 15.9812 20.6077C16.7117 20.2516 17.3163 19.6814 17.7147 18.9732C18.1673 18.1686 18.2296 17.1084 18.3544 14.988L19 4.01221M3 4.01221H1M3 4.01221H19M19 4.01221H21M15 4.01221L14.547 2.65326C14.3503 2.06315 14.252 1.76809 14.0695 1.54994C13.9084 1.35731 13.7016 1.2082 13.4679 1.11629C13.2032 1.01221 12.8922 1.01221 12.2702 1.01221H9.72982C9.10779 1.01221 8.79677 1.01221 8.53213 1.11629C8.29844 1.2082 8.09156 1.35731 7.93047 1.54994C7.74804 1.76809 7.64969 2.06315 7.45298 2.65326L7 4.01221M9 9.01221V16.0122M13 9.01221V13.0122',
  // ... more icons
} as const

export type IconNames = keyof typeof Icons

interface IconProperties {
  className?: string
  viewBox?: string
  title?: string
  style?: any
  role?: string
  size?: '8' | '16' | '24' | '32' | '40'
  name: IconNames
}

const Icon: React.FC<IconProperties> = ({ viewBox, title, size, name, ...props }) => (
  <svg width={size} height={size} viewBox={viewBox} fill="none" {...props}>
    {title &&
      <title>{title}</title>
    }
    <path d={Icons[name]()} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

Icon.defaultProps = {
  viewBox: '0 0 24 24',
  size: '24',
  role: 'img',
}

export { Icon }
