import axios from 'axios'

const uploadFile = async (file, statusCallback) => {
  const url = '/storage/files/rich-textarea'
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    },
    onUploadProgress: function(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      statusCallback && statusCallback(percentCompleted)
    }
  }
  try {
    const { data } = await axios.post(url, formData, config)
    return data.file
  } catch (e) {
    const message = e.response?.data?.error?.message || e.message
    throw new Error(message)
  }
}

export default uploadFile