import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'

export function useLabelFor(): readonly [
  string,
  { 'htmlFor': string }
] {
  const uniqueID = useMemo(uuid, [uuid])
  return [uniqueID, { 'htmlFor': uniqueID }]
}

export function useLabelledBy(): readonly [
  string,
  { 'aria-labelledby': string }
] {
  const uniqueID = useMemo(uuid, [uuid])
  return [uniqueID, { 'aria-labelledby': uniqueID }]
}

export function useDescribedBy(): readonly [
  string,
  { 'aria-describedby': string }
] {
  const uniqueID = useMemo(uuid, [uuid])
  return [uniqueID, { 'aria-describedby': uniqueID }]
}