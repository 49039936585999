import React, { createContext, useContext, useEffect, useRef } from "react"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { cn, Icon } from "@design-system"
import { cva, VariantProps } from "class-variance-authority"

const ScrollContext = createContext({ disabledScroll: false })

const useScrollContext = () => useContext(ScrollContext)

const rootVariants = cva(
  'group',
  {
    variants: {
      variant: {
        inset: 'v-inset border border-gray-30 dark:border-gray-90 bg-white dark:bg-gray-100 rounded-lg',
        outset: 'v-outset space-y-4',
      },
    },
    defaultVariants: {
      variant: 'outset',
    },
  }
)

/**
 * Root
 */
type ScrollContextType = { disabledScroll?: boolean }
type RootProps = React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root> &
  VariantProps<typeof rootVariants> & ScrollContextType

type RootRef = React.ElementRef<typeof AccordionPrimitive.Root>

const Root = React.forwardRef<RootRef, RootProps>(({ className, variant, disabledScroll = false, ...props }, ref) => (
  <ScrollContext.Provider value={{ disabledScroll }}>
    <AccordionPrimitive.Root ref={ref} className={cn(rootVariants({ variant }), className)} {...props} />
  </ScrollContext.Provider>
))
Root.displayName = "Accordion"

/**
 * RootHeader
 */
const RootHeader = ({ className, ...props }: React.HTMLAttributes<HTMLElement>) => {
  return (
    <header
      className={cn("flex items-center gap-2 group-[.v-inset]:px-6 group-[.v-inset]:py-4", className)}
      {...props}
    />
  )
}
RootHeader.displayName = "Accordion.RootHeader"

/***
 * Item
 */
const Item = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <AccordionPrimitive.Item
      ref={ref}
      className={cn("group-[.v-outset]:border group-[.v-outset]:border-gray-30 group-[.v-outset]:dark:border-gray-90 group-[.v-outset]:bg-white group-[.v-outset]:dark:bg-gray-100 group-[.v-outset]:rounded-lg group-[.v-outset]:px-6 group-[.v-outset]:py-4", className)}
      {...props}
    />
  )
})
Item.displayName = "Accordion.Item"

/**
 * Header
 */
const Header = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Header>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Header>
>(({ className, ...props }, ref) => {
  return (
    <AccordionPrimitive.Header
      ref={ref}
      className={cn("flex gap-2 [&[data-state=open]_#chevron-icon]:rotate-180 group-[.v-inset]:border-t group-[.v-inset]:border-gray-30 group-[.v-inset]:dark:border-gray-90 group-[.v-inset]:px-6 group-[.v-inset]:py-4", className)}
      {...props}
    />
  )
})
Header.displayName = "Accordion.Header"

/**
 * Trigger
 */
const Trigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>>(
  ({ className, children, ...props }, ref) => {
    const triggerRef = useRef<HTMLButtonElement | null>(null)
    const { disabledScroll } = useScrollContext()

    React.useImperativeHandle(ref, () => triggerRef.current!)

    const scrollIntoViewIfOpen = () => {
      if (!disabledScroll) {
        const triggerElement = triggerRef.current
        if (triggerElement && triggerElement.getAttribute("data-state") === "open") {
          setTimeout(() => {
            const rect = triggerElement.getBoundingClientRect()
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop
            const offsetPosition = rect.top + scrollTop - 160
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            })
          }, 300)
        }
      }
    }

    useEffect(() => {
      scrollIntoViewIfOpen()

      const triggerElement = triggerRef.current
      if (triggerElement) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === "attributes" && mutation.attributeName === "data-state") {
              scrollIntoViewIfOpen()
            }
          })
        })

        observer.observe(triggerElement, { attributes: true })

        return () => {
          observer.disconnect()
        }
      }
    }, [disabledScroll])

    return (
      <AccordionPrimitive.Trigger ref={triggerRef} className={cn("flex gap-2 text-left grow items-center", className)} {...props}>
        {children}
      </AccordionPrimitive.Trigger>
    )
  }
)

/**
 * Icon
 */
const AccordionIcon = () => {
  return (
    <Icon id="chevron-icon" name="chevron-down" size="md" className="flex shrink-0 transition-transform duration-200" />
  )
}
AccordionIcon.displayName = "Accordion.Icon"

/***
 * Content
 */
const Content = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, ...props }, ref) => {
  return (
    <AccordionPrimitive.Content
      ref={ref}
      className={cn("group-[.v-outset]:pt-4 overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down group-[.v-inset]:bg-gray-2 dark:group-[.v-inset]:bg-gray-98 group-[.v-inset]:border-t group-[.v-inset]:border-gray-30 group-[.v-inset]:dark:border-gray-90  group-[.v-inset]:px-6 group-[.v-inset]:py-4", className)}
      {...props}
    />
  )
})
Content.displayName = "Accordion.Content"

export const Accordion = Object.assign(Root, {
  RootHeader, Item, Header, Trigger, Icon: AccordionIcon, Content
})
