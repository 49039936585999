import React, { useState, ReactNode } from 'react'
import { Dialog, Button } from '@design-system'
import { Dialog as PrimitiveDialog } from '@headlessui/react'

interface ConfirmModalProps {
  /**
   * Control the modal open state
   */
  isOpen: boolean
  /**
   * Callback fired when the component requests to be closed.
   */
  onCancel: any
  /**
   * Callback fired when the confirm button is fired.
   */
  onConfirm: any
  /**
   * Modal Title
   */
  title: string
  /**
   * Modal subtitle
   */
  subtitle?: string
  /**
   * Text label for button with confirm action
   */
  confirmLabel?: string
  /**
   * Text label for button with cancel action
   */
  cancelLabel?: string
  /**
   * Color of the confirm button
   */
  confirmColor?: React.ComponentProps<typeof Button>['color'],
  /**
   * Children element placed between description and buttons
   */
  children?: ReactNode | undefined
}

/**
 * The ConfirmModal provides a easy-to-use Headless Ui Modal to confirm a action
 */
const ConfirmModal = ({
  isOpen,
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel,
  title,
  subtitle,
  confirmColor,
  children,
}: ConfirmModalProps) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const handleConfirm = async () => {
    setSubmitting(true)
    await onConfirm()
    setSubmitting(false)
  }
  return (
    <PrimitiveDialog as="div" className="relative z-60" open={isOpen} onClose={onCancel}>
      <div className="fixed inset-0 bg-black bg-opacity-25" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <PrimitiveDialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl flex flex-col gap-4 bg-white p-6 text-left align-middle shadow-xl transition-all">
            <Dialog.Header>
              <Dialog.Title data-testid="title">
                {title}
              </Dialog.Title>
              {subtitle && (
                <Dialog.Description data-testid="subtitle">
                  {subtitle}
                </Dialog.Description>
              )}
            </Dialog.Header>
            {children && <Dialog.Body>{children}</Dialog.Body>}
            <Dialog.Footer>
              <Button loading={isSubmitting} color={confirmColor} onClick={handleConfirm} data-testid="confirm">{confirmLabel}</Button>
              <Button disabled={isSubmitting} onClick={onCancel} variant="outlined" data-testid="cancel">{cancelLabel}</Button>
            </Dialog.Footer>
          </PrimitiveDialog.Panel>
        </div>
      </div>
    </PrimitiveDialog>
  )
}

ConfirmModal.defaultProps = {
  isOpen: false,
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  title: 'Are you sure?',
  isSubmitting: false,
}

ConfirmModal.displayName = 'ConfirmModal'

export { ConfirmModal }

export type { ConfirmModalProps }
