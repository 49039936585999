import React from 'react'
import PropTypes from 'prop-types'

const VideoElement = ({ attributes, children, element }) => {
  const { url } = element
  return (
    <div {...attributes}>
      <div className="max-w-lg m-auto">
        <div className="aspect-w-16 aspect-h-9 clear-right">
          <iframe
            src={`${url}`}
            width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
          />
        </div>
      </div>
      {children}
    </div>
  )
}

VideoElement.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.shape({
    url: PropTypes.any
  })
}

export default VideoElement