import * as React from "react"
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import { cn } from "../../lib/cn"

const Group = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("flex flex-row gap-2", className)}
      {...props}
      ref={ref}
    />
  )
})
Group.displayName = RadioGroupPrimitive.Root.displayName

const GroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & { label: string }
>(({ className, label, ...props }, ref) => {
  const id = React.useId()
  return (
    <div className="flex items-center space-x-2">
      <RadioGroupPrimitive.Item
        id={id}
        ref={ref}
        className={cn(
          "aspect-square h-4 w-4 rounded-full border border-black ring-offset-current focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
          <div className="h-2.5 w-2.5 bg-black rounded-full" />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
      <label htmlFor={id}>{label}</label>
    </div>
  )
})
GroupItem.displayName = RadioGroupPrimitive.Item.displayName

export const unstable_RadioGroup = Object.assign(Group, { Item: GroupItem })
