import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@design-system'

const spinnerVariants = cva(
  'animate-spin inline-block border-2 m-0.5 border-current border-t-transparent rounded-full',
  {
    variants: {
      /**
       * Indicate the color of the spinner.
       */
      color: {
        dark: 'text-gray-100 dark:text-white',
        light: 'text-white dark:text-gray-100',
        accent: 'text-accent',
        danger: 'text-danger',
        inherit: 'text-inherit'
      },
      /**
       * Indicate the size of the spinner.
       */
      size: {
        xs: 'size-3',
        sm: 'size-4',
        md: 'size-5',
        lg: 'size-7',
      },
    },
    defaultVariants: {
      color: 'dark',
      size: 'md',
    },
  }
)

export interface SpinnerProps extends VariantProps<typeof spinnerVariants> {
  /**
   * Avoid using className that affect the appearance of the spinner.
   */
  className?: string
}

/**
 * The spinner component can be used as a loading indicator which comes in multiple colors, sizes, and styles separately or inside elements such as buttons to improve the user experience whenever data is being fetched from your server.
 */
export function Spinner({ className, color, size }: SpinnerProps) {
  return (
    <span className={cn(spinnerVariants({ color, size, className }))} role='status' aria-label='loading'>
      <span className='sr-only'>Loading...</span>
    </span>
  )
}
