import React from 'react'
import { Button, ButtonProps } from '../Button'

interface ButtonTileProps extends Omit<ButtonProps, 'color' | 'variant' | 'size' | 'display'> {
  /**
   * When true is passed, the button will have a active appearance
   */
  active?: boolean
}

const DEFAULT_CLASSES = 'bg-gray-10 border border-gray-30 hover:border-gray-60 dark:bg-gray-100 dark:border-gray-100 dark:text-gray-5 dark:hover:border-gray-60'
const DISABLED_CLASSES = 'text-gray-70 border-gray-30 bg-gray-10 dark:bg-black dark:border-black dark:text-gray-50'

/**
 * Extended version of Button with custom layout similar to a card
 */
const ButtonTile = ({
  children,
  disabled,
  active,
  ...rest
}: ButtonTileProps
): JSX.Element => {
  return (
    <div className={`rounded-md border ${!disabled ? DEFAULT_CLASSES : DISABLED_CLASSES} ${active ? `!bg-gray-15 dark:!bg-gray-95 dark:!border-gray-80` : ``}`}>
      <Button disabled={disabled} {...rest} variant="inherit" size="auto" display="block">
        <span className='w-full font-regular'>{children}</span>
      </Button>
    </div>
  )
}

ButtonTile.defaultProps = {
  as: 'button',
  active: false,
  loading: false,
  disabled: false,
}

ButtonTile.displayName = 'ButtonTile'

export { ButtonTile }