import React from 'react'

const DEFAULT_SIZE = 100 as const

interface HorizontalLevelIndicatorProps {
  size: number,
  filled: number,
  piecesPercentages?: number[],
  height?: number
  fillColor?: string
}

export default function HorizontalLevelIndicator({
  size,
  filled,
  piecesPercentages,
  height = 4,
  fillColor = '#068062',
}: HorizontalLevelIndicatorProps) {
  let aux = filled
  const parsedPiecesPercentages =
    piecesPercentages ?? [...Array(size).keys()].map(() => DEFAULT_SIZE / size)
  const itemsPropsArray = [...Array(size).keys()].map((_, index) => {
    const perc = (aux - 1 > 0 ? 1 : aux > 0 ? aux : 0) * 100
    aux = aux - 1
    const background = `linear-gradient(to right, ${perc === 0 ? '#f0f1f2' : fillColor} 0% ${(perc).toFixed(0)}%, #f0f1f2 ${(perc).toFixed(0)}% ${(100 - perc).toFixed(0)}%)`
    const isLeftBorder = index === 0
    const isRightBorder = index === size - 1
    return {
      background,
      isLeftBorder,
      isRightBorder,
      width: parsedPiecesPercentages[index] + '%',
    }
  })

  return (
    <div className="flex space-x-[1px] w-full">
      {itemsPropsArray.map(
        ({ background, isLeftBorder, isRightBorder, width }, index) => (
          <span
            key={index}
            data-testid="piece"
            style={{ width, background }}
            className={`${isLeftBorder ? 'rounded-l-full' : ''} ${isRightBorder ? 'rounded-r-full' : ''} 
              h-${height}`}
          />
        )
      )}
    </div>
  )
}
