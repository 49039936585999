import React, { ElementType } from 'react'
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../Button'

const DEFAULT_ELEMENT = FontAwesomeIcon

type ALLOWED_ELEMENTS = typeof FontAwesomeIcon | typeof Button | 'div'

interface AdornmentOwnProps<T extends ElementType> {
  /**
   * Which component will render as Adornment. All `FontAwesomeIcon` or `Button` options will be available in the component.
   * @type FontAwesomeIcon | Button | div
   * @default FontAwesomeIcon
   */
  as?: T extends ALLOWED_ELEMENTS ? T : never
}

export type AdornmentProps<
  T extends ElementType = typeof DEFAULT_ELEMENT
> = PolymorphicPropsWithoutRef<AdornmentOwnProps<T>, T>

const getDefaultProps = (Element: any): Object => {
  switch (Element?.displayName) {
    case 'FontAwesomeIcon':
      return {
        size: 'xs',
        className: 'px-1'
      }
    case 'Button':
      return {
        variant: 'nude',
        color: 'neutral',
        size: 'xs',
        endIcon: undefined,
        children: undefined
      }
    default:
      return {}
  }
}

/**
 * This can be used to add a prefix, a suffix or an action to an input. For instance, you can use an Button to hide or reveal the password.
 */
const Adornment = <T extends ElementType = typeof DEFAULT_ELEMENT>({
  as,
  ...rest
}: AdornmentProps<T>) => {
  const Element: ElementType = as
  return (
    <Element data-testid='adornment' {...rest} {...getDefaultProps(Element)} />
  )
}

Adornment.defaultProps = {
  as: DEFAULT_ELEMENT
}

Adornment.displayName = 'Adornment'

export { Adornment }
