import React, { PropsWithChildren } from 'react'
import Header, { CardHeaderProps } from './header'
import Media, { CardMediaProps } from './media'

interface CardRootProps {
  /**
   * What background image to use
   */
  backgroundImage?: string
  /**
   * What background color to use
   */
  backgroundColor?: string
}

/**
 * CardRoot component
 */
const CardRoot = ({
  backgroundImage,
  backgroundColor = 'bg-green-2',
  children,
}: PropsWithChildren<CardRootProps>) => {
  const style = backgroundImage ? {
    backgroundImage: `url(${backgroundImage})`,
    boxShadow: 'inset 0 0 0 200vw rgba(0, 0, 0, 0.5)'
  } : null
  return (
    <article data-testid="card" className={`flex w-full h-full flex-col justify-between rounded bg-cover bg-center p-3 shadow-inn ${backgroundColor} ${backgroundImage ? `dark text-white` : ``}`} style={style}>
      {children}
    </article>
  )
}

export const Card = Object.assign(CardRoot, { Header, Media })
export type { CardRootProps, CardHeaderProps, CardMediaProps }
