import React, { forwardRef, useEffect, useRef, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { TextField } from '@designsystem'
import { Icon } from '@design-system'

interface DatepickerProps {
  defaultSelected: string
  name: string
  error?: string
  placeholder?: string
}

function Datepicker({ defaultSelected, name, error, placeholder, ...rest }: DatepickerProps) {
  const [selectedDate, setSelectedDate] = useState(new Date(defaultSelected))

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date)
  }

  return <>
    <ReactDatePicker
      selected={selectedDate}
      onChange={handleChangeDate}
      name={name}
      popperClassName="!z-10"
      /* @ts-expect-error */
      customInput={<DatePickerInputButton error={error} onChange={handleChangeDate} />}
      {...rest}
    />
  </>
}

const DatePickerInputButton = forwardRef((
  { name, value, error, onClick, placeholder }: { name: string, error?: string, fullWidth: boolean, value: string, placeholder?: string, onClick: () => void },
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const inputRef = useRef(null)
  const isFirstRenderRef = useRef(null)

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      isFirstRenderRef.current = true
      return
    }
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value').set;
    nativeInputValueSetter.call(inputRef.current, value);
    const event = new Event('input', { bubbles: true });
    inputRef.current.dispatchEvent(event);
  }, [value])

  return (
    <TextField
      type='text'
      ref={inputRef}
      name={name}
      error={error}
      placeholder={placeholder}
      defaultValue={value}
      onClick={onClick}
      endAdornment={<Icon name='chevron-down' />}
    />
  )
})

export { Datepicker as unstable_Datepicker }
