import * as React from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { cn } from '@design-system'
import { VariantProps, cva } from 'class-variance-authority'

const avatarVariants = cva(
  'relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full font-medium',
  {
    variants: {
      /**
       * Indicate the size of the avatar.
       */
      size: {
        xs: 'size-4 text-[0.5rem]',
        sm: 'size-5 text-tiny',
        md: 'size-6 text-tiny',
        lg: 'size-8 text-sm',
        xl: 'size-10 text-lg',
        '2xl': 'size-12 text-lg',
        '3xl': 'size-14 text-xl',
        '4xl': 'size-16 text-xl',
        '5xl': 'size-20 text-2xl',
        '6xl': 'size-12 text-lg lg:size-24 lg:text-3xl',
        '7xl': 'size-14 text-xl lg:size-28 lg:text-4xl',
        '8xl': 'size-16 text-xl lg:size-32 lg:text-5xl',
        '9xl': 'size-20 text-2xl lg:size-36 lg:text-6xl',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)

type AvatarProps = VariantProps<typeof avatarVariants> & AvatarPrimitive.AvatarProps

const Root = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, size, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(avatarVariants({ size, className }))}
    {...props}
  />
))
Root.displayName = AvatarPrimitive.Root.displayName

const Image = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('aspect-square h-full w-full object-cover object-center', className)}
    {...props}
  />
))
Image.displayName = AvatarPrimitive.Image.displayName

const Fallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, children, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'flex h-full w-full items-center justify-center rounded-full bg-gray-80 text-white uppercase',
      className
    )}
    {...props}
  >
    {typeof children === 'string' ? children.match(/\b(\w)/g).join('').replace(/^(.)(.*)(.)$/, '$1$3') : children}
  </AvatarPrimitive.Fallback>
))
Fallback.displayName = AvatarPrimitive.Fallback.displayName

/**
 * An image element with a fallback for representing the user.
 * 
 * [Docs](https://www.radix-ui.com/primitives/docs/components/avatar) - [API Reference](https://www.radix-ui.com/primitives/docs/components/avatar#api-reference)
 */
export const Avatar = Object.assign(Root, { Image, Fallback })
