import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as FlagOutline } from '../../assets/icons/outline/flag.svg'
import { ReactComponent as BoxOutline } from '../../assets/icons/outline/box.svg'
import { ReactComponent as RewriteOutline } from '../../assets/icons/outline/rewrite.svg'
import { ReactComponent as TrashOutline } from '../../assets/icons/outline/trash.svg'
import { ReactComponent as AddCommentOutline } from '../../assets/icons/outline/add-comment.svg'
import { ReactComponent as PencilEditOutline } from '../../assets/icons/outline/pencil-edit.svg'
import { ReactComponent as CloudDownloadOutline } from '../../assets/icons/outline/cloud_download.svg'
import { ReactComponent as CloudUploadOutline } from '../../assets/icons/outline/cloud_upload.svg'
import { ReactComponent as MailOutline } from '../../assets/icons/outline/mail_outline.svg'
import { ReactComponent as Announcement } from '../../assets/icons/filled/announcement.svg'
import { ReactComponent as Assignment } from '../../assets/icons/filled/assignment.svg'
import { ReactComponent as Check } from '../../assets/icons/filled/check.svg'
import { ReactComponent as Close } from '../../assets/icons/filled/close.svg'
import { ReactComponent as Event } from '../../assets/icons/filled/event.svg'
import { ReactComponent as Sparkles } from '../../assets/icons/filled/sparkles.svg'
import { ReactComponent as SendPaperPlane } from '../../assets/icons/outline/sendPaperPlane.svg'

export const soraFlagOutline = FlagOutline
export const soraBoxOutline = BoxOutline
export const soraRewriteOutline = RewriteOutline
export const soraTrashOutline = TrashOutline
export const soraAddCommentOutline = AddCommentOutline
export const soraPencilEditOutline = PencilEditOutline
export const soraCloudDownloadOutline = CloudDownloadOutline
export const soraCloudUploadOutline = CloudUploadOutline
export const soraMailOutline = MailOutline
export const soraAnnouncement = Announcement
export const soraAssignment = Assignment
export const soraCheck = Check
export const soraClose = Close
export const soraEvent = Event
export const soraSparkles = Sparkles
export const soraSendPaperPlane = SendPaperPlane

const SoraIcon = ({ icon, className }) => {
  return <div>
    {icon({ className: `fill-current ${className}` })}
  </div>
}
SoraIcon.propTypes = {
  icon: PropTypes.any.isRequired,
  className: PropTypes.string
}

export default SoraIcon
