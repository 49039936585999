import React from 'react'
import { Link, LinkProps, useLocation, useNavigation, useResolvedPath, useSearchParams } from 'react-router-dom'

interface SoraNavLinkProps extends Omit<SoraLinkProps, "className"> {
  className?: string | ((isActive: boolean) => string)
}

export const SoraNavLink: React.FC<SoraNavLinkProps> = ({ className, to, children, ...rest }) => {
  const navigation = useNavigation()
  const location = useLocation()
  const resolvedPath = useResolvedPath(to)

  const pathname = navigation.location?.pathname || location.pathname

  const isActive = pathname.includes(resolvedPath.pathname)

  const props = {
    'aria-current': isActive ? 'page' : undefined,
  }

  return (
    <SoraLink
      to={to}
      className={typeof className === 'function' ? className(isActive) : className}
      {...rest}
      {...props}
    >
      {children}
    </SoraLink>
  )
}

interface SoraLinkProps extends Omit<LinkProps, "aria-current"> {
  as?: React.ElementType
}

export default function SoraLink({ as, to, children, ...rest }: SoraLinkProps) {
  const [params] = useSearchParams()
  const cycle_id = params.get('cycle_id')
  const Element = as || Link

  if (cycle_id && typeof to === 'string') {
    const [path, searchString = ''] = to.split('?')
    const queryParams = new URLSearchParams(searchString)
    queryParams.set('cycle_id', cycle_id)
    const finalPath = path + '?' + queryParams.toString()
    return (
      <Element to={finalPath} {...rest}>
        {children}
      </Element>
    )
  }

  if (cycle_id && typeof to === 'object') {
    const queryParams = new URLSearchParams(to.search)
    queryParams.set('cycle_id', cycle_id)
    return (
      <Element
        to={{
          ...to,
          search: queryParams.toString(),
        }}
        {...rest}
      >
        {children}
      </Element>
    )
  }

  return (
    <Element to={to} {...rest}>
      {children}
    </Element>
  )
}
