import SlateTextarea, { BlockButton, LinkButton, MarkButton } from '@components/forms/slate-textarea'
import { Icon, useFetcher } from '@design-system'
import React from 'react'

export function CreatePresetCommentSlateTextArea({ name, body = null }) {
  const fetcher = useFetcher()

  return (
    <SlateTextarea
      className="h-32"
      name={name}
      value={body}
      customTopBar={
        <>
          <div className="flex items-center border-b-[1px] border-neutral-300  sticky top-0 bg-white z-5 h-9 py-1">
            <MarkButton
              disabled={false}
              format="bold"
              icon={<Icon className="leading-['inherit']" name="text-bold" size="md" />}
            />
            <MarkButton
              disabled={false}
              format="italic"
              icon={<Icon className="leading-['inherit']" name="text-italic" size="md" />}
            />
            <BlockButton
              disabled={false}
              format="numbered-list"
              icon={<Icon className="leading-['inherit']" name="list-ordered" size="md" />}
            />
            <BlockButton
              disabled={false}
              format="bulleted-list"
              icon={<Icon className="leading-['inherit']" name="list-unordered" size="md" />}
            />
            <LinkButton disabled={false} />
            <MarkButton
              disabled={false}
              format="strikethrough"
              icon={<Icon className="leading-['inherit']" name="text-strikethrough" size="md" />}
            />
            <MarkButton
              disabled={false}
              format="underline"
              icon={<Icon className="leading-['inherit']" name="text-underline" size="md" />}
            />
          </div>
        </>
      }
    />
  )
}
