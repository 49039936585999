import * as React from 'react'
import { Button, Icon, IconProps, Typography, cn, ButtonProps } from '@design-system'
import { cva, type VariantProps } from 'class-variance-authority'

const alertVariants = cva(
  'p-4 rounded-lg space-x-4 grid [grid-template-areas:"icon_title_close""icon_description_close""icon_description_close""icon_actions_actions"] md:[grid-template-areas:"icon_title_close""icon_description_close""icon_description_actions"] grid-cols-[auto_1fr_auto]',
  {
    variants: {
      variant: {
        default: 'bg-gray-10 dark:bg-gray-90',
        success: 'bg-green-5 dark:bg-green-80',
        notice: 'bg-notice-10 dark:bg-notice-80',
        danger: 'bg-danger-2 dark:bg-danger-80',
      },
    },
    defaultVariants: {
      variant: 'default'
    },
  }
)

interface AlertProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof alertVariants>, React.PropsWithChildren {
}

const icons: Record<AlertProps['variant'], IconProps['name']> = {
  default: 'info',
  success: 'check-circle-2',
  notice: 'alert-circle',
  danger: 'cross-circle',
}

function Root({ variant, className, children, ...props }: AlertProps) {
  return (
    <div role="alert" className={cn(alertVariants({ variant, className }))} {...props}>
      <Icon data-testid="alert-icon" name={icons[variant ?? 'default']} size="md" className="text-gray-100 dark:text-gray-20 [grid-area:icon]" />
      {children}
    </div>
  )
}

function Title({ children }: React.PropsWithChildren) {
  return <Typography weight="bold" asChild className="[grid-area:title]">
    <h3>
      {children}
    </h3>
  </Typography>
}

function Description({ children }: React.PropsWithChildren) {
  return <Typography className="[grid-area:description]">{children}</Typography>
}

interface CloseButtonProps extends Omit<ButtonProps, 'variant' | 'size' | 'children' | 'className'> { }

function CloseButton(props: CloseButtonProps) {
  return (
    <Button type="submit" variant="ghost" size="sm" className="[grid-area:close]" {...props} >
      <Icon name="cross" size="sm" />
    </Button>
  )
}

function Actions({ children }: React.PropsWithChildren) {
  return (
    <div className="flex gap-2 mt-2 md:mt-0 [grid-area:actions]">
      {children}
    </div>
  )
}

export const Alert = Object.assign(Root, {
  Title,
  Description,
  CloseButton,
  Actions,
})
