"use client"

import * as CollapsiblePrimitive from "@radix-ui/react-collapsible"

const Root = CollapsiblePrimitive.Root

const Trigger = CollapsiblePrimitive.CollapsibleTrigger

const Content = CollapsiblePrimitive.CollapsibleContent

export const unstable_Collapsible = Object.assign(Root, {
  Trigger,
  Content,
})
