import React from 'react'
import { cn } from '@design-system'

/**
 * Use to show a placeholder while content is loading.
 */
export function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      data-testid="skeleton"
      className={cn("relative rounded-sm bg-gray-10 dark:bg-gray-95 overflow-hidden", className)}
      {...props}>
      <div className="animate-slide-in absolute inset-0 bg-gradient-to-r from-gray-10 dark:from-gray-95 via-white dark:via-gray-85 to-gray-10 dark:to-gray-95"></div>
    </div>
  )
}
